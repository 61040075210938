import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useTopPanel from 'hooks/useTopPanel';
import OrderFiltersComponent from 'components/ui/filters/OrderFiltersComponent';
import { orderTypeVariants } from 'shared/tableColVariantsList';
import BaseTable from 'components/ui/tables/BaseTable';
import SearchInputNavbar from 'components/SearchInputNavbar';

const Order = () => {
  const { setComponent } = useTopPanel();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setComponent(<div className='component-title'>{t('order.title')}</div>);
  }, [i18n.language]);

  const [refetch, setRefetch] = useState(false);
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);

  return (
    <div className='component-list-wrapper'>
      <div className='component-list-header mb-2'>
        <div className='header-actions-container pt-2 pb-2 flex flex-row items-center'>
          <div className='header-actions filter-box flex items-center'>
            <Button
              variant='base'
              startIcon={<i className='bi bi-filter' />}
              className='!mr-2'
              onClick={() => setExpanded((prev) => !prev)}
            >
              {t('common.button.filter')}
            </Button>
          </div>
          <div className='grow'>
            <SearchInputNavbar inputKey='name' fullWidth />
          </div>
          <div className='header-actions action-buttons-box flex items-center justify-center ml-4'>
            <Button
              variant='base'
              onClick={() => {
                setRefetch(true);
              }}
              disable={`${refetch}`}
            >
              <i
                className={`bi bi-arrow-repeat${
                  refetch ? ' animate-spin' : ''
                }`}
              />
            </Button>
            <Button
              variant='base'
              className='!ml-2'
              onClick={() => setOpen(true)}
            >
              <i className='bi bi-gear' />
            </Button>
          </div>
        </div>
        {expanded && (
          <div className='base-border shadow-md bg-white px-4 w-full'>
            <OrderFiltersComponent />
          </div>
        )}
      </div>

      <div className='component-table-wrapper h-[calc(100vh-140px)]'>
        <BaseTable
          emitRefetch={{ refetch, setRefetch }}
          dataPath='admin/order/index'
          emitTableColumns={{ open, setOpen }}
          tableName='order'
          headCells={[
            { code: 'custom', label: t('common.table.custom') },
            { code: 'object', label: t('common.table.objectName') },
            { code: 'block', label: t('common.table.blockName') },
            { code: 'home_number', label: t('common.table.homeNumber') },
            { code: 'home_stage', label: t('common.table.stage') },
            { code: 'home_rooms', label: t('common.table.rooms') },
            { code: 'date', label: t('common.table.date') },
            { code: 'status', label: t('common.table.status') },
          ]}
          columns={[
            {
              code: 'custom',
              type: 'nestedChain',
              childStrings: ['surname', 'name', 'middlename'],
            },
            { code: 'home', type: 'nested', childStr: 'blocks.objects.name' },
            { code: 'home', type: 'nested', childStr: 'blocks.name' },
            { code: 'home', type: 'nested', childStr: 'number' },
            { code: 'home', type: 'nested', childStr: 'stage' },
            { code: 'home', type: 'nested', childStr: 'rooms' },
            { code: 'date', type: 'date' },
            {
              code: 'status',
              type: 'customStatus',
              variants: orderTypeVariants,
            },
          ]}
          actionInfo={true}
        />
      </div>
    </div>
  );
};

export default Order;
