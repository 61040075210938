import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
} from '@mui/material';

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

const MultiSelect = ({
  size,
  title,
  queryKeys,
  isEventAvailable,
  blockList = [],
}) => {
  const { t } = useTranslation();
  const [selectedBlocks, setSelectedBlocks] = useState([]);
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    isEventAvailable && setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchTerm('');
  };

  const clearAll = () => {
    const entries = Object.fromEntries(searchParams);
    Object.keys(entries).forEach((item) => {
      if (
        item.includes(`${queryKeys?.childQuery}[`) ||
        item.includes(`${queryKeys?.mainQuery}[`)
      )
        searchParams.delete(item);
    });
  };

  const handleClearSelectBox = (e) => {
    e?.stopPropagation();

    clearAll();
    setSelectedBlocks([]);
    setSelectedObjects([]);
    setSearchParams(searchParams);
  };

  const onSelectObject = (e, id) => {
    e.stopPropagation();
    if (!selectedObjects?.includes(id)) {
      searchParams.set(`${queryKeys?.mainQuery}[${id}]`, id);
      setSelectedObjects((ids) => [...ids, id]);
    } else {
      searchParams.delete(`${queryKeys?.mainQuery}[${id}]`);

      setSelectedObjects((ids) => ids?.filter((val) => val !== id));
    }
    setSearchParams(searchParams);
  };
  const onSelectPipe = (e, id) => {
    e.stopPropagation();

    if (!selectedBlocks?.includes(id)) {
      searchParams.set(`${queryKeys?.childQuery}[${id}]`, id);
      setSelectedBlocks((ids) => [...ids, id]);
    } else {
      searchParams.delete(`${queryKeys?.childQuery}[${id}]`);

      setSelectedBlocks((ids) => ids?.filter((val) => val !== id));
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const entries = Object.fromEntries(searchParams);
    const objects = [];
    const blocks = [];
    Object.entries(entries).forEach(([key, value]) => {
      if (key.includes(`${queryKeys?.childQuery}[`))
        blocks?.push(Number(value));
      if (key.includes(`${queryKeys?.mainQuery}[`))
        objects?.push(Number(value));
    });
    setSelectedObjects(objects);
    setSelectedBlocks(blocks);
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <FormControl className='!border-none !m-0' fullWidth color='formColor'>
      <TextField
        id='multi-select-textfield'
        size={size}
        label={title}
        variant='outlined'
        onChange={handleSearchChange}
        onClick={handleClick}
        value={
          selectedBlocks?.length || selectedObjects?.length
            ? `${t('common.filter.selectedObjects')} (${
                selectedBlocks.length + selectedObjects.length
              })`
            : searchTerm
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {selectedBlocks.length + selectedObjects.length > 0 && (
                <IconButton
                  onClick={(e) => handleClearSelectBox(e)}
                  size='small'
                >
                  <i className='bi bi-x end-adornment-close-button' />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
        className='h-[34px] !border-[#0000003b] !rounded-lg !text-gray-600 !justify-start'
      />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className='!shadow-none'
        PaperProps={{
          sx: { marginTop: '4px', width: '300px' },
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: { padding: 0 },
        }}
      >
        {blockList &&
          blockList.length > 0 &&
          blockList.map((item, index) => (
            <Accordion className='!shadow-none !p-0 !m-0'>
              <AccordionSummary
                className='flex-row-reverse  !p-0 !m-0'
                expandIcon={<i className='bi bi-caret-down-fill !px-3' />}
                onClick={(e) => e.stopPropagation()}
                aria-controls='panel1-content'
                id='panel1-header'
              >
                <MenuItem
                  value={item?.id}
                  key={`object-${index + 1}`}
                  fullWidth
                  className={`!w-full !h-full !m-0 !text-[12px] !normal-case  ${
                    selectedObjects?.includes(item?.id) && '!bg-green-300'
                  }`}
                  onClick={(e) => onSelectObject(e, item?.id)}
                >
                  {item?.name}
                </MenuItem>
              </AccordionSummary>
              <AccordionDetails className='!p-0 !pl-14'>
                {item?.pipes?.map((pipe) => {
                  return (
                    <MenuItem
                      key={`pipe-${index}-${pipe.id}`}
                      className={` !text-[12px] !normal-case  ${
                        selectedBlocks?.includes(pipe?.id) && '!bg-green-300'
                      }`}
                      value={pipe?.id}
                      onClick={(e) => onSelectPipe(e, pipe?.id)}
                    >
                      {pipe?.name}
                    </MenuItem>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          ))}
      </Menu>
    </FormControl>
  );
};

export default MultiSelect;
