import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Trigger({ setOpenTriggerAddModal }) {
  const { t } = useTranslation();
  return (
    <div
      onClick={() => setOpenTriggerAddModal(true)}
      className='min-h-[110px] h-[110px] group border-b'
    >
      <div className='w-full h-full flex items-center cursor-pointer justify-center gap-2 opacity-0 group-hover:opacity-50'>
        <i className='bi bi-plus-circle' />
        {t('common.button.createTrigger')}
      </div>
    </div>
  );
}
