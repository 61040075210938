import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import FormActionButtons from 'components/ui/form/FormActionButtons';
import { useFormik } from 'formik';
import useFormSubmit from 'hooks/useFormSubmit';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import FormTreeSelectField from 'components/ui/form/FormTreeSelectField';
import FormCheckboxField from 'components/ui/form/FormCheckboxField';
import CommentTaskTypesSelect from '../../card-add-edit/comment-box-items/CommentTaskTypesSelect';

const validationSchema = yup.object({
  name: yup.string().required('crm.block.column.addModal.validation.name'),
});

const TaskTrigger = (props) => {
  const {
    open,
    setOpen,
    refetchFn,
    blockId,
    lastOrder = 0,
    selected = {},
    setSelected = () => {},
  } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const { submit, isSubmitting } = useFormSubmit();

  const formik = useFormik({
    initialValues: {
      name: '',
      color: '#87898E',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        crm_block_id: blockId,
        order: selected?.order || lastOrder + 1,
      };
      if (blockId) {
        submit(
          { type: selected?.id ? 'put' : 'post', contentType: 'simple' },
          newValues,
          // '/crm/block-item',
          `/crm/block-item${selected?.id ? '/' + selected?.id : ''}`,
          values.name,
          null,
          true,
          handleFinish
        );
      }
    },
  });

  useEffect(() => {
    if (selected?.id) {
      formik.setValues({
        name: selected?.name || '',
        color: selected?.color || '#87898E',
      });
    }
    return () => {};
  }, [selected]);

  const handleFinish = () => {
    refetchFn();
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    setSelected({});
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleSelectColor = (color) => formik?.setFieldValue('color', color);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      disableEscapeKeyDown={true}
      PaperProps={{ className: '!base-border !shadow-none !max-w-[500px]' }}
    >
      <DialogTitle
        id='alert-dialog-title'
        className='!bg-gray-100 !text-center !mb-4'
      >
        <span>{t('common.button.createTask')}</span>
      </DialogTitle>

      <DialogContent>
        <form onSubmit={formik.handleSubmit} className='pt-2'>
          <Grid
            container
            spacing={{ xs: 1, sm: 1 }}
            rowSpacing={1}
            columns={{ xs: 1, sm: 1 }}
          >
            <Grid item={true} sm={1} xs={1} className='!items-center flex'>
              <FormTreeSelectField
                delay={0}
                duration={0}
                parentFieldName='crm_block_id'
                childFieldName='crm_block_item_id'
                label=''
                childOptionField={'pipes'}
                formik={formik}
                parentLabel={'name'}
                parentValue={'id'}
                childLabel={'name'}
                childValue={'id'}
                variant='standard'
                path='/crm/block'
                size='small'
                onChange={() => {}}
                progress={false}
                // onChange={({ requireds }) => setRequiredFields(requireds || [])}
              />
            </Grid>
            <Grid item={true} sm={1} xs={1}>
              <CommentTaskTypesSelect
                // selectedTaskType={selectedTaskType}
                selectedTaskType={{ name: 'Uchrasuv' }}
                // setSelectedTaskType={setSelectedTaskType}
              />
            </Grid>
            <Grid item={true} sm={1} xs={1}>
              <FormCheckboxField
                delay={0.1}
                label={t('crm.settings.applyStatusAllLeads')}
                fieldName='name'
                formik={formik}
              />
            </Grid>

            <Grid item={true} sm={12} xs={12}>
              <div className='flex justify-end'>
                <FormActionButtons
                  delay={0.2}
                  isSubmitting={isSubmitting}
                  formType='dialog'
                  setOpen={setOpen}
                  // disabled={selected?.id}
                  reset={formik.resetForm}
                />
              </div>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default memo(TaskTrigger);
