import { Button, CircularProgress } from '@mui/material';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { useCallback, useEffect, useRef, useState, memo, useMemo } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useInfiniteQuery } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import AcceptAction from './AcceptAction';
import moment from 'moment/moment';
import { t } from 'i18next';
import { useSearchParams } from 'react-router-dom';
import EditableTextField from 'components/ui/editable-fields/EditableTextField';

const CRMBlockDroppableCards = ({
  leadData = [],
  column = {},
  provided = {},
  columnId = '',
  parentDroppableId = '',
  setLeadsData = () => {},
  onSelect = () => {},
  handleUpdateColumn = () => {},
  editable,
}) => {
  const [searchParams] = useSearchParams();
  const [enabled, setEnabled] = useState(false);
  const [isLeadDataLoading, setIsLeadDataLoading] = useState(true);
  const [count, setCount] = useState(0);
  const axiosPrivate = useAxiosPrivate();
  const intObserver = useRef();
  const columnRef = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const { search } = useLocation();

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  const onIntersection = useCallback((id, visible) => {
    if (visible) setIsVisible(visible);
  }, []);

  const callbackFunction = useCallback(
    (entries) => {
      const [entry] = entries;
      onIntersection(column?.id, entry?.isIntersecting);
    },
    [onIntersection, column]
  );

  const options = useMemo(
    () => ({
      root: null, // viewport
      rootMargin: '0px', // no margin
      threshold: 0.2, // 20% of target visible
    }),
    []
  );

  useEffect(() => {
    let observerRefValue = null; // <-- variable to hold ref value

    const observer = new IntersectionObserver(callbackFunction, options);

    if (columnRef.current) {
      observer.observe(columnRef.current);
      observerRefValue = columnRef.current; // <-- save ref value
    }

    return () => {
      if (observerRefValue) observer.unobserve(observerRefValue); // <-- use saved value
    };
  }, [callbackFunction, options, columnRef, column, columnId, enabled]);

  useEffect(() => {
    setLeadsData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const { fetchNextPage, hasNextPage, isFetchingNextPage, refetch } =
    useInfiniteQuery(
      [`leadData${columnId}`, search, isVisible],
      ({ pageParam = 1 }) => getLeadsByPage(pageParam),
      {
        onSuccess: (data) => {
          if (data && data.pages.length > 0) {
            setLeadsData((prevLeads) => {
              let newLeadsData = JSON.parse(JSON.stringify(prevLeads));
              if (newLeadsData.hasOwnProperty(`${columnId}`)) {
                newLeadsData[`${columnId}`] = data.pages.flat(2);
              } else {
                newLeadsData[`${columnId}`] = data.pages.flat(2);
              }
              return newLeadsData;
            });
          }
          setIsLeadDataLoading(false);
        },
        enabled: columnId && columnId > 0 && enabled && isVisible,
        getNextPageParam: (lastPage, allPages) => {
          return lastPage.length ? allPages.length + 1 : undefined;
        },
      }
    );

  const fields = useMemo(() => {
    const fs = Object.entries(Object.fromEntries(searchParams))
      ?.filter((arr) => arr?.[0]?.includes('fields['))
      ?.map((arr) => {
        arr?.splice(0, 1, arr?.[0]?.replace('fields[', '')?.replace(']', ''));
        return arr;
      });
    const obj = {};
    fs?.forEach((arr) => {
      obj[`${arr?.[0]}`] = arr?.[1];
    });
    return obj;
  }, [searchParams]);

  const getLeadsByPage = async (pageParam = 1, options = {}) => {
    const params = Object.fromEntries(
      Object.entries({ ...Object.fromEntries(searchParams) }).filter(
        (arr) => !arr?.[0]?.includes('fields[')
      )
    );

    const response = await axiosPrivate.post(
      `/crm/crm-lead/index/${columnId}`,
      { ...params, fields, page: pageParam }
    );
    setCount(response.data?.data?.total);
    return response.data?.data?.data;
  };

  const lastLeadRef = useCallback(
    (leadD) => {
      if (isFetchingNextPage) return;

      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver((leads) => {
        if (leads[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });

      if (leadD) intObserver.current.observe(leadD);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  const defineDiff = useCallback((day) => {
    const now = moment();
    const _day = moment(day, 'YYYY-MM-DD');
    const diff = _day.diff(now, 'days');
    return diff + 1;
  }, []);

  if (!enabled) {
    return null;
  }

  return (
    <>
      <div className='crm-block-grid-column-title-wrapper' ref={columnRef}>
        <div
          className={
            'crm-block-grid-column-title gap-2 overflow-hidden flex h-[52px] items-center flex-wrap'
          }
          style={{ background: column?.color || 'rgb(135, 137, 142)' }}
        >
          {editable ? (
            <div
              className='drag-btn'
              style={{ letterSpacing: '-10px' }}
              {...provided.dragHandleProps}
            >
              <i className='bi bi-three-dots-vertical' />
              <i className='bi bi-three-dots-vertical' />
            </div>
          ) : null}
          {/* <div className='absolute top-0 left-0 right-0 h-1' /> */}
          {editable ? (
            <EditableTextField
              initialText={column.name}
              changeFn={handleUpdateColumn(column)}
              className={
                'text-white [&>.MuiFormControl-root]:m-0 [&>.MuiInputBase-root]:min-h-[28px] [&_input]:text-white'
              }
              // style={{ color: column?.color }}
            />
          ) : (
            <span className='text-white'>{column?.name}</span>
          )}
          <span className='text-[10px] text-white'>({count || 0})</span>
          {editable ? (
            <div className='absolute top-1/2 -translate-y-1/2 right-1 cursor-pointer'>
              <Button
                className='!min-w-[30px] !w-[30px] !text-white'
                sx={{ minWidth: '30px', width: '30px' }}
                onClick={() => onSelect(column)}
              >
                <i className='bi bi-three-dots-vertical' />
              </Button>
            </div>
          ) : null}
        </div>
      </div>

      <div className='crm-block-grid-column-body'>
        <div className='crm-block-grid-column-body'>
          {isLeadDataLoading ? (
            <div className='flex items-center justify-center bg-white h-full max-h-[200px] rounded-sm'>
              <CircularProgress size={25} color='inherit' />
            </div>
          ) : (
            <Droppable droppableId={parentDroppableId} type='CARD'>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={`column-cards-wrapper${
                    snapshot.isDraggingOver ? ' card-dragging-over-column' : ''
                  }${
                    Boolean(snapshot.draggingFromThisWith).toString()
                      ? ' card-dragging-from-column'
                      : ''
                  }`}
                >
                  {leadData && leadData.length > 0
                    ? leadData.map((card, index) => (
                        <Draggable
                          draggableId={`card-${card.id}`}
                          index={index}
                          key={`card-${card.id}`}
                        >
                          {(providedCard, snapshotCard) => (
                            <div
                              className='relative group mb-4'
                              ref={providedCard.innerRef}
                              {...providedCard.draggableProps}
                              {...providedCard.dragHandleProps}
                            >
                              <Link
                                to={`/admin/crm/lead/edit/${card.id}`}
                                className={`column-card no-underline${
                                  false ? ' scale-110' : ''
                                }`}
                                key={`card-${card.id}`}
                              >
                                <div className='flex flex-col h-full'>
                                  <div className='text-[#818181] flex h-8 border-bottom p-2 border-[#EAEFF4] bg-[#F7F9FA] items-center justify-between'>
                                    <span>{card?.name}</span>
                                    <span>
                                      {moment(card?.last_action).format(
                                        'DD.MM.YYYY HH:mm'
                                      )}
                                    </span>
                                  </div>
                                  <div className='p-2 text-[14px] flex flex-col gap-1 relative overflow-hidden radial-md'>
                                    <div className='flex items-center gap-1'>
                                      <span className='text-[#818181]'>
                                        F.I.O:
                                      </span>
                                      <span className='text-[#252C32]'>
                                        {card?.contactone?.name || ''}
                                      </span>
                                    </div>
                                    <div className='flex items-center gap-1'>
                                      <span className='text-[#818181]'>
                                        {t('common.fields.phone')}:
                                      </span>
                                      <span className='text-[#252C32]'>
                                        {card?.contactone?.phone?.[0] || ''}
                                      </span>
                                    </div>
                                    <div className='flex items-center gap-1 flex-wrap'>
                                      <span className='text-[#818181]'>
                                        {t('tasks.oneTask')}:
                                      </span>
                                      {card?.checktask?.date ? (
                                        <span
                                          className={`${
                                            defineDiff(card?.checktask?.date) >
                                            0
                                              ? 'text-green-500'
                                              : 'text-red-500'
                                          }`}
                                        >
                                          {defineDiff(card?.checktask?.date)}{' '}
                                          {t('common.fields.day')}
                                        </span>
                                      ) : (
                                        <span className='text-orange-500'>
                                          {t('tasks.noTask')}
                                        </span>
                                      )}
                                    </div>
                                    {!card?.user_id && (
                                      <AcceptAction
                                        id={card?.id}
                                        refetch={refetch}
                                      />
                                    )}
                                  </div>
                                </div>
                              </Link>
                            </div>
                          )}
                        </Draggable>
                      ))
                    : ''}

                  {isFetchingNextPage ? (
                    <div className='flex items-center justify-center bg-transparent rounded-lg py-2'>
                      <CircularProgress size={20} color='inherit' />
                    </div>
                  ) : (
                    <div
                      className='observable-item h-0.5 opacity-0'
                      id={`observable-item-${columnId}`}
                      ref={lastLeadRef}
                    />
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )}
        </div>{' '}
      </div>
    </>
  );
};
export default memo(CRMBlockDroppableCards);
