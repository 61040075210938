import NavBarLinkItem from 'components/navbar-link/NavBarLinkItem';
import { useTranslation } from 'react-i18next';

const BossCrmSidebarPanel = () => {
  const { t } = useTranslation();

  return (
    <div className='sidebar-panel-wrapper !shadow-none border-r border-r-[#E5E9EB] overflow-y-auto h-full'>
      <ul className='sidebar-links-wrapper !shadow-none mt-2 p-3'>
        <NavBarLinkItem
          linkData={{
            path: 'boss/crm/general',
            title: t('sidebar.boss.crmSidebar.general'),
          }}
          iconName='bi bi-window'
          delay={0}
        />
        <NavBarLinkItem
          linkData={{
            path: 'boss/crm/calls',
            title: t('sidebar.boss.crmSidebar.calls'),
          }}
          iconName='bi bi-telephone'
          delay={0.1}
        />
        <NavBarLinkItem
          linkData={{
            path: 'boss/crm/assignments',
            title: t('sidebar.boss.crmSidebar.assignments'),
          }}
          iconName='bi bi-list-task'
          delay={0.2}
        />
        <NavBarLinkItem
          linkData={{
            path: 'boss/crm/leads',
            title: t('sidebar.boss.crmSidebar.leads'),
          }}
          iconName='bi bi-window-desktop'
          delay={0.3}
        />
        <NavBarLinkItem
          linkData={{
            path: 'boss/crm/conversions',
            title: t('sidebar.boss.crmSidebar.conversions'),
          }}
          iconName='bi bi-filter'
          delay={0.4}
        />
      </ul>
    </div>
  );
};

export default BossCrmSidebarPanel;
