import { Button, Grid } from '@mui/material';
import useMenu from 'hooks/useMenu';
import useTopPanel from 'hooks/useTopPanel';
import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Settings = () => {
  const { setComponent } = useTopPanel();
  const { hasChild } = useMenu();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setComponent(<div className='component-title'>{t('settings.title')}</div>);
  }, [i18n.language]);

  const view = (child) => {
    return hasChild('SETTINGS', child);
  };

  return (
    <div className='mt-6 setting-wrapper'>
      <Grid
        container
        spacing={{ xs: 2, sm: 2, lg: 2 }}
        columns={{ xs: 12, sm: 12, lg: 12 }}
      >
        {view('SETTINGS_COMPANY') && (
          <Grid item={true} lg={4} sm={6} xs={12}>
            <Link to='/admin/settings/company' className='settings-item'>
              <Button
                fullWidth
                variant='outlined'
                className='settings-item-btn'
              >
                <i className='bi bi-buildings text-3xl' />
                <span className='ml-2'>{t('settings.company')}</span>
              </Button>
            </Link>
          </Grid>
        )}

        {view('SETTINGS_STAFF') && (
          <Grid item={true} lg={4} sm={6} xs={12}>
            <Link to='/admin/settings/staff' className='settings-item'>
              <Button
                fullWidth
                variant='outlined'
                className='settings-item-btn'
              >
                <i className='bi bi-people text-3xl' />
                <span className='ml-2'>{t('settings.staffSettings')}</span>
              </Button>
            </Link>
          </Grid>
        )}

        {view('SETTINGS_PERMISSIONS') && (
          <Grid item={true} lg={4} sm={6} xs={12}>
            <Link to='/admin/settings/permission' className='settings-item'>
              <Button
                fullWidth
                variant='outlined'
                className='settings-item-btn'
              >
                <i className='bi bi-person-lock text-3xl' />
                <span className='ml-2'>{t('settings.permissionSettings')}</span>
              </Button>
            </Link>
          </Grid>
        )}

        {view('SETTINGS_VALUTE') && (
          <Grid item={true} lg={4} sm={6} xs={12}>
            <Link to='/admin/settings/currency' className='settings-item'>
              <Button
                fullWidth
                variant='outlined'
                className='settings-item-btn'
              >
                <i className='bi bi-coin text-3xl' />
                <span className='ml-2'>{t('settings.currencySettings')}</span>
              </Button>
            </Link>
          </Grid>
        )}

        {view('SETTINGS_HOMES') && (
          <Grid item={true} lg={4} sm={6} xs={12}>
            <Link to='/admin/settings/home' className='settings-item'>
              <Button
                fullWidth
                variant='outlined'
                className='settings-item-btn'
              >
                <i className='bi bi-building-gear text-3xl' />
                <span className='ml-2'>{t('settings.homeSettings')}</span>
              </Button>
            </Link>
          </Grid>
        )}

        {view('OBJECTS') && (
          <Grid item={true} lg={4} sm={6} xs={12}>
            <Link to='/admin/settings/object' className='settings-item'>
              <Button
                fullWidth
                variant='outlined'
                className='settings-item-btn'
              >
                <i className='bi bi-houses text-3xl' />
                <span className='ml-2'>{t('sidebar.admin.object')}</span>
              </Button>
            </Link>
          </Grid>
        )}

        {view('BLOCKS') && (
          <Grid item={true} lg={4} sm={6} xs={12}>
            <Link to='/admin/settings/block' className='settings-item'>
              <Button
                fullWidth
                variant='outlined'
                className='settings-item-btn'
              >
                <i className='bi bi-buildings text-3xl' />
                <span className='ml-2'>{t('sidebar.admin.block')}</span>
              </Button>
            </Link>
          </Grid>
        )}

        {view('SETTINGS_CRM') && (
          <Grid item={true} lg={4} sm={6} xs={12}>
            <Link to='/admin/settings/crm' className='settings-item'>
              <Button
                fullWidth
                variant='outlined'
                className='settings-item-btn'
              >
                <i className='bi bi-database-gear text-3xl' />
                <span className='ml-2'>{t('settings.crm.title')}</span>
              </Button>
            </Link>
          </Grid>
        )}

        {view('SETTINGS_TARGET') && (
          <Grid item={true} lg={4} sm={6} xs={12}>
            <Link to='/admin/settings/targets' className='settings-item'>
              <Button
                fullWidth
                variant='outlined'
                className='settings-item-btn'
              >
                <i class='bi bi-bullseye text-3xl' />
                <span className='ml-2'>{t('settings.targets.title')}</span>
              </Button>
            </Link>
          </Grid>
        )}

        {view('SETTINGS_CONTRACT') && (
          <Grid item={true} lg={4} sm={6} xs={12}>
            <Link to='/admin/settings/contracts' className='settings-item'>
              <Button
                fullWidth
                variant='outlined'
                className='settings-item-btn'
              >
                <i class='bi bi-file-earmark-text text-3xl' />
                <span className='ml-2'>{t('settings.contracts.title')}</span>
              </Button>
            </Link>
          </Grid>
        )}
        {view('SMS') && (
          <Grid item={true} lg={4} sm={6} xs={12}>
            <Link to='/admin/settings/sms' className='settings-item'>
              <Button
                fullWidth
                variant='outlined'
                className='settings-item-btn'
              >
                <i class='bi bi-envelope text-3xl' />
                <span className='ml-2'>{t('settings.sms.title')}</span>
              </Button>
            </Link>
          </Grid>
        )}
        {view('EVENTS') && (
          <Grid item={true} lg={4} sm={6} xs={12}>
            <Link to='/admin/settings/events' className='settings-item'>
              <Button
                fullWidth
                variant='outlined'
                className='settings-item-btn'
              >
                <i className='bi bi-calendar4-event text-3xl' />
                <span className='ml-2'>{t('settings.events.title')}</span>
              </Button>
            </Link>
          </Grid>
        )}
        {view('DISCOUNTS') && (
          <Grid item={true} lg={4} sm={6} xs={12}>
            <Link to='/admin/settings/discounts' className='settings-item'>
              <Button
                fullWidth
                variant='outlined'
                className='settings-item-btn'
              >
                <i className='bi bi-percent text-3xl' />
                <span className='ml-2'>{t('settings.discounts.title')}</span>
              </Button>
            </Link>
          </Grid>
        )}
        {view('INTERFACE') && (
          <Grid item={true} lg={4} sm={6} xs={12}>
            <Link to='/admin/settings/interface' className='settings-item'>
              <Button
                fullWidth
                variant='outlined'
                className='settings-item-btn'
              >
                <i className='bi bi-brush text-3xl' />
                <span className='ml-2'>{t('settings.interface.title')}</span>
              </Button>
            </Link>
          </Grid>
        )}
        {view('SETTINGS_SENT_MESSAGES') && (
          <Grid item={true} lg={4} sm={6} xs={12}>
            <Link to='/admin/settings/sent-messages' className='settings-item'>
              <Button
                fullWidth
                variant='outlined'
                className='settings-item-btn'
              >
                <i className='bi bi-envelope-arrow-up text-3xl' />
                <span className='ml-2'>{t('settings.sentMessages.title')}</span>
              </Button>
            </Link>
          </Grid>
        )}
        {view('VARIABLES') && (
          <Grid item={true} lg={4} sm={6} xs={12}>
            <Link to='/admin/settings/variables' className='settings-item'>
              <Button
                fullWidth
                variant='outlined'
                className='settings-item-btn'
              >
                <i className='bi bi-code-slash text-3xl' />
                <span className='ml-2'>{t('settings.variables.title')}</span>
              </Button>
            </Link>
          </Grid>
        )}

        {view('PAYMENT_CHANGES') && (
          <Grid item={true} lg={4} sm={6} xs={12}>
            <Link to='/admin/settings/payment-change' className='settings-item'>
              <Button
                fullWidth
                variant='outlined'
                className='settings-item-btn'
              >
                <i className='bi bi-cash-stack text-3xl' />
                <span className='ml-2'>{t('sidebar.admin.paymentChange')}</span>
              </Button>
            </Link>
          </Grid>
        )}

        {view('NEWS') && (
          <Grid item={true} lg={4} sm={6} xs={12}>
            <Link to='/admin/settings/news' className='settings-item'>
              <Button
                fullWidth
                variant='outlined'
                className='settings-item-btn'
              >
                <i className='bi bi-newspaper text-3xl' />
                <span className='ml-2'>{t('sidebar.admin.news')}</span>
              </Button>
            </Link>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default memo(Settings);
