import { Grid } from '@mui/material';
// import EditableTextField from 'components/ui/editable-fields/EditableTextField';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useNotification from 'hooks/useNotification';
import { memo, useCallback, useEffect, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
// import useMenu from 'hooks/useMenu';
import MiniMap from 'components/MiniMap';
import CRMBlockDroppableCards from '../card/items/CRMBlockDroppableCards';

const CRMBlockDroppableColumn = ({
  data = [],
  setOpenAddModal = () => {},
  // setOpenOperatorsModal = () => {},
  leadsData = [],
  setLeadsData = () => {},
  setSelected = () => {},
}) => {
  const [enabled, setEnabled] = useState(false);
  const sendNotification = useNotification();
  const axiosPrivate = useAxiosPrivate();
  // const { hasChild } = useMenu();

  // const view = (code) => hasChild('CRM', code);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  const handleUpdateColumn = (column) => async (newName) => {
    try {
      await axiosPrivate.post(
        `/crm/block-item/${column?.id}`,
        JSON.stringify({
          crm_block_id: column?.crm_block_id,
          name: newName,
          order: column?.order,
          _method: 'put',
        }),
        { headers: { 'Content-Type': 'application/json' } }
      );
    } catch (error) {
      sendNotification({
        msg: error?.response?.data?.message || error?.message,
        variant: 'error',
      });
    }
  };

  const onSelect = useCallback((column) => {
    setSelected(column);
    setOpenAddModal(true);
  }, []);

  if (!enabled) {
    return null;
  }

  return (
    <Droppable
      droppableId='crm-block-columns'
      type='COLUMN'
      direction='horizontal'
      isDropDisabled
    >
      {(provided) => (
        <div
          className='flex crm-block-columns-grid'
          id='crm-blocks-wrapper'
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {data &&
            data.length > 0 &&
            data.map((column, index) => (
              <Draggable
                draggableId={`${column.id}`}
                index={index}
                key={`column-${column.id}`}
                isDragDisabled
              >
                {(provided, snapshot) => (
                  <Grid
                    item={true}
                    sm={1}
                    xs={1}
                    className={`crm-block-grid-column !bg-[#FCFCFC] !p-2 !rounded-md !border !border-[#5959591A] ${
                      snapshot.isDragging ? 'is-dragging' : ''
                    }`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                  >
                    {/* <div className='crm-block-grid-column-title-wrapper'>
                      <div className='drag-btn' {...provided.dragHandleProps}>
                        <i className='bi bi-grid-3x2-gap-fill' />
                      </div>
                      <div
                        className={`crm-block-grid-column-title !pb-1 overflow-hidden`}
                      >
                        <div
                          style={{ background: column?.color }}
                          className='absolute top-0 left-0 right-0 h-1'
                        />
                        <EditableTextField
                          initialText={column.name}
                          changeFn={handleUpdateColumn(column)}
                          className={'text-center'}
                          style={{ color: column?.color }}
                        />
                        <center className='text-[10px] text-gray-400'>
                          {column?.leads_count || 0}
                        </center>
                      </div>
                    </div>

                    <div className='crm-block-grid-column-body'>
                    </div> */}
                    <CRMBlockDroppableCards
                      leadData={leadsData[`${column.id}`] || []}
                      columnId={column.id}
                      parentDroppableId={`${column.id}`}
                      column={column}
                      handleUpdateColumn={handleUpdateColumn}
                      provided={provided}
                      setLeadsData={setLeadsData}
                      onSelect={onSelect}
                      setSelected={setSelected}
                    />
                  </Grid>
                )}
              </Draggable>
            ))}
          {provided.placeholder}
          <div className='sticky right-0 flex flex-col'>
            {/* {view('CRM_ADD_BLOCK_ITEM') && (
              <div className='pl-4 mb-2'>
                <Button
                  type='button'
                  variant='actionLarge'
                  color='primary'
                  onClick={() => setOpenAddModal(true)}
                >
                  <i className='bi bi-plus' />
                </Button>
              </div>
            )}

            {view('CRM_OPERATOR') && (
              <div className='pl-4'>
                <Button
                  type='button'
                  variant='actionLarge'
                  color='primary'
                  onClick={() => setOpenOperatorsModal(true)}
                >
                  <i className='bi bi-person-gear' />
                </Button>
              </div>
            )} */}
          </div>
          <MiniMap
            mainContainerId='crm-blocks-wrapper'
            itemsNumber={data.length}
          />
        </div>
      )}
    </Droppable>
  );
};
export default memo(CRMBlockDroppableColumn);
