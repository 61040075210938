/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Button, Tab, Tabs } from '@mui/material';
import FormTextField from 'components/ui/form/FormTextField';
import { useFormik } from 'formik';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useFormSubmit from 'hooks/useFormSubmit';
import useTopPanel from 'hooks/useTopPanel';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import CRMCardLeadHistory from './CRMCardLeadHistory';
import { InfoSection, SettingsSection, StatisticsSection } from './edit-items';
import useMenu from 'hooks/useMenu';
import SearchLeadModal from './combine-leads/SearchLeadModal';
import OrderLeadModal from './combine-leads/OrderLeadModal';
import RejectTypeModal from './reject-type-modal/RejectTypeModal';
import useNotification from 'hooks/useNotification';
import FormTreeSelectField from 'components/ui/form/FormTreeSelectField';

const validationSchema = yup.object({
  name: yup.string().min(3).required(),
  crm_block_id: yup.string().nullable(),
  crm_block_item_id: yup.string(),
  user_id: yup.string().nullable(),
  budget: yup.number().nullable(),
  contacts: yup
    .array()
    .of(
      yup.object({
        id: yup.string().optional(),
        name: yup.string().nullable()
      })
    ),
});

const CRMCardAddEdit = () => {
  const { setComponent } = useTopPanel();
  const { hasChild } = useMenu();
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const sendNotification = useNotification();
  const { submit, isSubmitting } = useFormSubmit();
  const [hasError, setHasError] = useState(false);
  const [openSearchLead, setOpenSearchLead] = useState(false);
  const [orderModalOpen, setOrderModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [checkedList, setCheckedList] = useState([id]);
  const [tabIndex, setTabIndex] = useState(1);
  const [requiredFields, setRequiredFields] = useState([]);
  const [refetchGetField, setRefetchGetField] = useState(false);

  const view = (code) => hasChild('CRM', code);

  const formik = useFormik({
    initialValues: {
      name: '',
      crm_block_id: '',
      crm_block_item_id: '',
      user_id: '',
      budget: '',
      isfinished: false,
      reject_id: '',
      fields: [],
      contacts: [
        {
          name: '',
          phones: [
            {
              phone: '',
            },
          ],
        },
      ],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const fields = values?.fields?.filter(({ value }) => value);
      const body = { ...values, fields };
      if (
        requiredFields?.length &&
        !requiredFields?.every(
          ({ field_id }) =>
            fields?.find((field) => field?.field_id == field_id)?.value
        )
      ) {
        const emptyFields = values?.fields?.filter(
          ({ field_id, value }) =>
            requiredFields?.find((field) => field?.field_id == field_id) &&
            !value
        );

        emptyFields?.forEach(({ field_name }) => {
          if (field_name) {
            sendNotification({
              msg: field_name + ' kiritish majburiy!',
              variant: 'warning',
            });
          }
        });
        return;
      } else if (values?.isfinished == 1 && !values?.reject_id) {
        return setRejectModalOpen(true);
      } else if (id) {
        submit(
          { type: 'put', contentType: 'simple' },
          body,
          `/crm/crm-lead/${id}`,
          body.name,
          null,
          true,
          refetch
        );
      } else
        submit(
          { type: 'post', contentType: 'simple' },
          body,
          '/crm/crm-lead',
          body.name,
          null,
          true
        );

      setRefetchGetField(true);
    },
  });

  const { isLoading, isFetching, refetch, data } = useQuery({
    queryKey: ['/crm/crm-lead/edit/{id}', id],
    queryFn: async function () {
      const response = await axiosPrivate.get(`/crm/crm-lead/edit/${id}`);
      return response.data.data;
    },
    onSuccess: (res) => {
      if (res?.dubls?.length) {
        const doubles = res?.dubls
          ?.map((lead) => lead?.id)
          ?.sort((a, b) => (a == id ? -1 : 1));
        setCheckedList(doubles);
      }
      const data = res?.lead;
      if (data) {
        formik.setValues({
          name: data?.name || '',
          crm_block_id: data?.crm_block_id || '',
          crm_block_item_id: data?.crm_block_item_id || '',
          user_id: data?.user_id || '',
          budget: data?.budget ?? '',
          fields: [],
          isfinished: data?.reject_id ? true : false,
          reject_id: data?.reject_id,
          contacts:
            data?.contacts && data?.contacts?.length > 0
              ? data?.contacts
              : [
                  {
                    name: '',
                    // region_id: '',
                    phones: [
                      {
                        phone: '',
                      },
                    ],
                  },
                ],
        });
        setRefetchGetField(true);
      }
    },
    enabled: !hasError && !!id,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });
  const { data: actionList } = useQuery({
    queryKey: ['/admin/event/actions'],
    queryFn: async function () {
      const response = await axiosPrivate.get('/admin/event/actions');
      return response.data.data;
    },

    enabled: !hasError && !!id,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });
  const { data: blockList } = useQuery({
    queryKey: ['/crm/block'],
    queryFn: async function () {
      const response = await axiosPrivate.get('/crm/block');
      return response.data.data;
    },

    enabled: !hasError && !!id,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });
  const { data: userList } = useQuery({
    queryKey: ['/dictionary/staffes'],
    queryFn: async function () {
      const response = await axiosPrivate.get('/dictionary/staffes');
      return response.data.data;
    },

    enabled: !hasError && !!id,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  const handleAddPhone = (contactInd) => {
    formik.setFieldValue(
      `contacts.${contactInd}.phones`,
      [
        ...JSON.parse(
          JSON.stringify(formik.values.contacts[contactInd].phones)
        ),
        {
          phone: '',
        },
      ],
      true
    );
  };

  const handleAddContact = () => {
    formik.setFieldValue(
      'contacts',
      [
        ...JSON.parse(JSON.stringify(formik.values.contacts)),
        {
          name: '',
          region_id: '',
          phones: [
            {
              phone: '',
            },
          ],
        },
      ],
      true
    );
  };

  const handleRemoveContact = (contactInd) => {
    let newContacts = JSON.parse(JSON.stringify(formik.values.contacts)).filter(
      (item, index) => index != contactInd
    );
    formik.setFieldValue('contacts', [...newContacts], true);
  };
  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  const getActionName = useCallback(
    (event) => {
      return actionList?.find((action) => action?.key === event)?.name || '';
    },
    [actionList]
  );

  const pipesList = useMemo(
    () =>
      blockList
        ?.map(({ pipes, name }) =>
          pipes?.map((p) => ({ ...p, blockName: name }))
        )
        ?.flat(Infinity) || [],
    [blockList]
  );

  const getStaffName = useCallback(
    (id) => {
      return userList?.find((user) => user?.id === id)?.name || '';
    },
    [userList]
  );

  const getVaronkaColorName = useCallback(
    (id) => {
      return pipesList?.find((pipe) => pipe?.id === id) || '';
    },
    [pipesList]
  );

  useEffect(() => {
    setComponent(
      <div className='crm-card-add-edit-top-wrapper items-center'>
        <div className='ml-4 mb-6'>
          <FormTextField
            delay={0}
            duration={0}
            label='Nomi'
            fieldName='name'
            formik={formik}
            variant='standard'
            className='[&_.MuiInputBase-root]:before:!border-none'
          />
        </div>
        {id ? (
          <div className='ml-4'>
            <Button
              delay={0}
              variant='base'
              duration={0}
              startIcon={<i className='bi bi-link-45deg' />}
              onClick={() => setOpenSearchLead(true)}
            >
              {t('crm.combine.title')}
            </Button>
          </div>
        ) : (
          ''
        )}
        {id && data?.dubls?.length >= 2 ? (
          <div className='ml-4'>
            <Button
              delay={0}
              variant='base'
              color='error'
              className='!bg-red-400 !text-white'
              duration={0}
              onClick={() => setOrderModalOpen(true)}
            >
              {t('crm.combine.doubles')}
            </Button>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }, [
    id,
    formik.values.name,
    formik.errors.name,
    formik.touched.name,
    i18n.language,
  ]);

  return (
    <div className='component-add-edit-wrapper crm-card-add-edit-wrapper'>
      <div className='component-add-edit-body crm-card-add-edit-body'>
        <div className='crm-card-add-edit-body-wrapper'>
          <div className='!w-[calc(30%-20px)] border-r'>
            <div className='item-value pr-4'>
              <FormTreeSelectField
                delay={0}
                duration={0}
                parentFieldName='crm_block_id'
                childFieldName='crm_block_item_id'
                label=''
                childOptionField={'pipes'}
                formik={formik}
                parentLabel={'name'}
                parentValue={'id'}
                childLabel={'name'}
                childValue={'id'}
                variant='standard'
                path='/crm/block'
                size='small'
                onChange={({ requireds }) => setRequiredFields(requireds || [])}
                isitemsizesmall={true}
              />
            </div>
            <Box
              sx={{
                background: '#76768014',
                padding: '3px 5px',
                borderRadius: '15px',
                marginRight: '20px',
              }}
            >
              <Tabs
                value={tabIndex}
                onChange={handleChangeTab}
                variant='fullWidth'
                type='callsHistory'
                className='custom-tab-fulfilled'
                sx={{
                  minHeight: 40,
                  height: 40,
                }}
              >
                <Tab
                  label={t('crm.block.info.title')}
                  value={1}
                  sx={{
                    fontSize: 12,
                    fontWeight: '400',
                    minHeight: 40,
                    height: 40,
                  }}
                />
                <Tab
                  label={t('crm.block.statistics.title')}
                  value={2}
                  sx={{
                    fontSize: 12,
                    fontWeight: '400',
                    minHeight: 40,
                    height: 40,
                  }}
                />
                {view('SETTINGS') && (
                  <Tab
                    label={t('crm.block.settings.title')}
                    value={3}
                    sx={{
                      fontSize: 12,
                      fontWeight: '400',
                      minHeight: 35,
                      height: 35,
                    }}
                  />
                )}
              </Tabs>
            </Box>

            {tabIndex == 1 && (
              <InfoSection
                formik={formik}
                handleAddPhone={handleAddPhone}
                handleRemoveContact={handleRemoveContact}
                handleAddContact={handleAddContact}
                isSubmitting={isSubmitting}
                refetch={refetchGetField}
                refetchLead={refetch}
                setRefetch={setRefetchGetField}
                isLoadingLeadInfo={isLoading || isFetching}
                setRequiredFields={setRequiredFields}
              />
            )}
            {tabIndex == 2 && (
              <StatisticsSection createdAt={data?.lead?.created_at} />
            )}
            {view('SETTINGS') && tabIndex == 3 && (
              <SettingsSection formik={formik} />
            )}
          </div>
          <div className='lead-history-body-wrapper !w-[calc(70%-350px)] grow bg-[#f0f2f5]'>
            <CRMCardLeadHistory
              isUserNew={!id}
              getActionName={getActionName}
              getStaffName={getStaffName}
              getVaronkaColorName={getVaronkaColorName}
            />
          </div>

          {openSearchLead && (
            <SearchLeadModal
              open={openSearchLead}
              setOpen={setOpenSearchLead}
              checkedList={checkedList}
              setOrderModalOpen={setOrderModalOpen}
              setCheckedList={setCheckedList}
            />
          )}
          {orderModalOpen && (
            <OrderLeadModal
              open={orderModalOpen}
              setOpen={setOrderModalOpen}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
            />
          )}
          {rejectModalOpen && (
            <RejectTypeModal
              open={rejectModalOpen}
              setOpen={setRejectModalOpen}
              formik={formik}
            />
          )}
          <div className='h-full min-w-[350px] border-l'>
            {/* <CrmVidgets /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(CRMCardAddEdit);
