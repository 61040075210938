import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import FilterAccordion from '../FilterAccordion';
import { useTranslation } from 'react-i18next';
import DateRangeFilter from 'components/ui/filters/items/DateRangeFilter';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { memo } from 'react';

const radios = [
  {
    code: 'tilltoday',
    label: 'tasks.filter.date.tilltoday',
  },
  {
    code: 'today',
    label: 'tasks.filter.date.today',
  },
  {
    code: 'yesterday',
    label: 'tasks.filter.date.yesterday',
  },
  {
    code: 'week',
    label: 'tasks.filter.date.week',
  },
  {
    code: 'month',
    label: 'tasks.filter.date.month',
  },
  {
    code: 'custom',
    label: 'tasks.filter.date.custom',
  },
];

const DateTypeFilter = ({ disabled = false, queries, label }) => {
  const fromQuery = queries?.[0] || 'from';
  const tillQuery = queries?.[1] || 'till';
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleRepairTypeChange = (value) => {
    if (value == 'tilltoday') {
      searchParams.delete(fromQuery);
      searchParams.delete(tillQuery);
    }
    if (value == 'today') {
      searchParams.set(fromQuery, moment().format('YYYY-MM-DD'));
      searchParams.set(tillQuery, moment().format('YYYY-MM-DD'));
    }
    if (value == 'yesterday') {
      searchParams.set(
        fromQuery,
        moment().subtract(1, 'days').format('YYYY-MM-DD')
      );
      searchParams.set(
        tillQuery,
        moment().subtract(1, 'days').format('YYYY-MM-DD')
      );
    }
    if (value == 'week') {
      searchParams.set(
        fromQuery,
        moment().clone().startOf('isoWeek').format('YYYY-MM-DD')
      );
      searchParams.set(tillQuery, moment().format('YYYY-MM-DD'));
    }
    if (value == 'month') {
      searchParams.set(
        fromQuery,
        moment().clone().startOf('month').format('YYYY-MM-DD')
      );
      searchParams.set(tillQuery, moment().format('YYYY-MM-DD'));
    }
    searchParams.set('type-date-picker', value);
    setSearchParams(searchParams);
  };

  return (
    <FilterAccordion
      title={`${label || ''}${t(
        `tasks.filter.date.${
          searchParams.get('type-date-picker') || 'tilltoday'
        }`
      )}`}
      code='home-repair-type'
    >
      <FormControl fullWidth color='formColor' type='newFilterRadioGroup'>
        <RadioGroup
          aria-labelledby='repair-type-radio-buttons-group'
          name='repair-type-radio-buttons-group-name'
          value={searchParams.get('type-date-picker') || 'tilltoday'}
          onChange={(event) => handleRepairTypeChange(event.target.value)}
        >
          {radios.map((option) => (
            <div className='mb-1 w-full' key={option.code}>
              <FormControlLabel
                className='w-full'
                value={option.code}
                control={<Radio />}
                label={t(option.label)}
                type='radio'
                disabled={disabled}
              />
            </div>
          ))}
          {searchParams.get('type-date-picker') == 'custom' ? (
            <DateRangeFilter />
          ) : null}
        </RadioGroup>
      </FormControl>
    </FilterAccordion>
  );
};
export default memo(DateTypeFilter);
