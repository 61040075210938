import axios from 'axios';
import { memo } from 'react';
import { useQuery } from 'react-query';
import { useAppInfo } from 'context';

export default memo(function Root({ children }) {
  const [, dispatch] = useAppInfo();
  const { REACT_APP_API_URL, REACT_APP_BACKEND_URL } = process.env;
  useQuery({
    queryKey: '/interface',
    queryFn: async function () {
      const response = await axios.get(`${REACT_APP_API_URL}/interface`);
      return response.data;
    },
    onSuccess: (res) => {
      if (res?.status) {
        const { data } = res;
        document.title = data?.name;
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.head.appendChild(link);
        }
        link.href = `${REACT_APP_BACKEND_URL}/${data?.image}`;
        const formatted = {
          ...data,
          image: `${REACT_APP_BACKEND_URL}/${data?.image}`,
        };

        document.body.style.setProperty(
          '--shaxmatka-active-home',
          data?.active_home
        );
        document.body.style.setProperty(
          '--shaxmatka-active-home-text',
          data?.active_home === '#FFFFFF' ? '#000000' : '#FFFFFF'
        );
        document.body.style.setProperty(
          '--shaxmatka-sold-home',
          data?.sold_home
        );
        document.body.style.setProperty(
          '--shaxmatka-sold-home-text',
          data?.sold_home === '#FFFFFF' ? '#000000' : '#FFFFFF'
        );
        document.body.style.setProperty(
          '--shaxmatka-ordered-home',
          data?.odered_home
        );
        document.body.style.setProperty(
          '--shaxmatka-ordered-home-text',
          data?.odered_home === '#FFFFFF' ? '#000000' : '#FFFFFF'
        );
        document.body.style.setProperty(
          '--shaxmatka-disabled-home',
          data?.disabled_home
        );
        document.body.style.setProperty(
          '--shaxmatka-disabled-home-text',
          data?.disabled_home === '#FFFFFF' ? '#000000' : '#FFFFFF'
        );
        document.body.style.setProperty(
          '--shaxmatka-completed-home',
          data?.complete
        );
        document.body.style.setProperty(
          '--shaxmatka-completed-home-text',
          data?.complete === '#FFFFFF' ? '#000000' : '#FFFFFF'
        );
        dispatch({ type: 'setInfo', payload: formatted });
      }
    },
    // onError: (error) => {
    // 	setHasError(true)
    // },
    retry: false,
  });
  return children;
});
