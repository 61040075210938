import { ButtonBase, CircularProgress, IconButton } from '@mui/material';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueries } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { FreeMode, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import BlocksView from './views/BlocksView';
import HomesView from './views/HomesView';
import StagesView from './views/StagesView';

const ShaxmatkaVisualView = ({ toggleHomeItem = () => {} }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [prevObjectId, setPrevObjectId] = useState(
    searchParams.get('objectId')
  );
  const [sliderActiveIndex, setSliderActiveIndex] = useState(0);
  const [selectedBlockId, setSelectedBlockId] = useState(null);
  const [selectedStage, setSelectedStage] = useState(null);
  const axiosPrivate = useAxiosPrivate();
  const swiperRef = useRef(null);

  const [blocksVisualQuery, stagesVisualQuery, homesVisualQuery] = useQueries([
    {
      queryKey: 'shaxmatkaBlocksVisualView',
      queryFn: async function () {
        const response = await axiosPrivate.get(
          `/admin/visual/object/${searchParams.get('objectId')}`
        );
        return response.data.data;
      },
      enabled: Boolean(searchParams.get('objectId')),
      retry: false,
    },
    {
      queryKey: ['shaxmatkaStagesVisualView', selectedBlockId],
      queryFn: async function () {
        if (selectedBlockId) {
          const response = await axiosPrivate.get(
            `/admin/visual/block/${selectedBlockId}`
          );
          return response.data.data;
        }
        return [];
      },
      enabled: Boolean(selectedBlockId),
      retry: false,
    },
    {
      queryKey: ['shaxmatkaHomesVisualView', selectedStage],
      queryFn: async function () {
        if (selectedBlockId && selectedStage) {
          const response = await axiosPrivate.get(
            `/admin/visual/homes/${selectedBlockId}?stage=${selectedStage}`
          );
          return response.data.data;
        }
        return [];
      },

      enabled: Boolean(selectedStage),
      retry: false,
    },
  ]);

  useEffect(() => {
    if (
      searchParams.get('objectId') &&
      searchParams.get('objectId') != prevObjectId
    ) {
      setPrevObjectId(searchParams.get('objectId'));
      blocksVisualQuery.refetch();
    }
  }, [searchParams]);

  const handleBackFromStages = () => {
    setSelectedBlockId(null);
    toggleHomeItem(null, null);
  };

  const handleBackFromHomes = () => {
    setSelectedStage(null);
    toggleHomeItem(null, null);
  };

  return (
    <div className='sheet-wrapper'>
      <div className='sheet-base-area relative'>
        {blocksVisualQuery.isLoading ||
        blocksVisualQuery.isFetching ||
        stagesVisualQuery.isLoading ||
        stagesVisualQuery.isFetching ? (
          <div className='circular-progress-box min-h-[500px] h-full w-full'>
            <CircularProgress size={50} />
          </div>
        ) : selectedStage ? (
          <div className='shaxmatka-visual-view-homes-wrapper'>
            <div className='stages-swiper-wrapper'>
              <div className='stages-swiper-body'>
                <Swiper
                  lazy='true'
                  direction='vertical'
                  freeMode={true}
                  slidesPerView={8}
                  spaceBetween={12}
                  pagination={false}
                  modules={[FreeMode, Pagination]}
                  onBeforeInit={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                  onSlideChange={(a) => {
                    setSliderActiveIndex(a?.activeIndex);
                  }}
                  className='h-full !pb-2 !pt-1'
                >
                  {stagesVisualQuery.data.blocks &&
                    stagesVisualQuery.data.blocks.length > 0 &&
                    stagesVisualQuery.data.blocks.map((block, index) => (
                      <SwiperSlide
                        key={`swiper-item-${index}`}
                        className='min-h-[60px]'
                      >
                        <div className='swiper-stage-item'>
                          <ButtonBase
                            className={`stage-btn${
                              selectedStage == block?.stage
                                ? ' is-selected'
                                : ''
                            }`}
                            onClick={() => {
                              setSelectedStage(block?.stage);
                              toggleHomeItem(null, null);
                            }}
                          >
                            {block?.stage}
                          </ButtonBase>
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper>
                <div
                  className={`swiper-navigation-button-wrapper prev${
                    sliderActiveIndex == 0 ? ' is-disabled' : ''
                  }`}
                >
                  <IconButton
                    variant='swiperNavigationButton'
                    color='primary'
                    onClick={() => {
                      swiperRef.current?.slidePrev();
                    }}
                    disabled={sliderActiveIndex == 0}
                    size='small'
                  >
                    <i className='bi bi-chevron-left' />
                  </IconButton>
                </div>
                <div
                  className={`swiper-navigation-button-wrapper next${
                    sliderActiveIndex ==
                    stagesVisualQuery.data.blocks.length - 1
                      ? ' is-disabled'
                      : ''
                  }`}
                >
                  <IconButton
                    variant='swiperNavigationButton'
                    color='primary'
                    onClick={() => {
                      swiperRef.current?.slideNext();
                    }}
                    disabled={
                      sliderActiveIndex ==
                      stagesVisualQuery.data.blocks.length - 1
                    }
                    size='small'
                  >
                    <i className='bi bi-chevron-right' />
                  </IconButton>
                </div>
              </div>
            </div>
            <div className='homes-view-wrapper'>
              {homesVisualQuery.isLoading || homesVisualQuery.isFetching ? (
                <div className='circular-progress-box min-h-[500px] h-full w-full'>
                  <CircularProgress size={35} />
                </div>
              ) : homesVisualQuery.data && homesVisualQuery.data?.id ? (
                <HomesView
                  data={homesVisualQuery.data}
                  stagesData={stagesVisualQuery.data}
                  backFn={handleBackFromHomes}
                  selectedBlockId={selectedBlockId}
                  selectedStage={selectedStage}
                  onClickPolygonFn={(homeId, selectedBId, ...prp) => {
                    toggleHomeItem(homeId, selectedBId, ...prp);
                  }}
                />
              ) : (
                <div className='shaxmatka-visual-view-wrapper'>
                  <span className='no-data-found-wrapper h-full'>
                    <i className='bi bi-exclamation-octagon text-xl leading-4 mr-1' />{' '}
                    {t('common.global.noDataFound')}
                  </span>
                </div>
              )}
            </div>
          </div>
        ) : selectedBlockId ? (
          stagesVisualQuery.data && stagesVisualQuery.data?.id ? (
            <StagesView
              data={stagesVisualQuery.data}
              selectedStage={selectedStage}
              selectedBlockId={selectedBlockId}
              backFn={handleBackFromStages}
              onClickPolygonFn={(value) => {
                setSelectedStage(value);
                toggleHomeItem(null, null);
              }}
            />
          ) : (
            <div className='shaxmatka-visual-view-wrapper'>
              <span className='no-data-found-wrapper h-full'>
                <i className='bi bi-exclamation-octagon text-xl leading-4 mr-1' />{' '}
                {t('common.global.noDataFound')}
              </span>
            </div>
          )
        ) : blocksVisualQuery.data && blocksVisualQuery.data?.id ? (
          <BlocksView
            data={blocksVisualQuery.data}
            selectedBlockId={selectedBlockId}
            setSelectedBlockId={setSelectedBlockId}
          />
        ) : (
          <div className='shaxmatka-visual-view-wrapper'>
            <span className='no-data-found-wrapper h-full'>
              <i className='bi bi-exclamation-octagon text-xl leading-4 mr-1' />{' '}
              {t('common.global.noDataFound')}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
export default memo(ShaxmatkaVisualView);
