import React, { memo } from 'react';
import { Grid } from '@mui/material';
import { t } from 'i18next';
import moment from 'moment';

import CLIENT_TYPE from 'shared/clientTypeList';
// import PhoneFormat from 'components/ui/text-formats/PhoneFormat';
import {
  UserIcon,
  CalendarIcon,
  CakeIcon,
  CommentUserIcon,
  BrowserIcon,
  PhoneIcon,
  LocationIcon,
} from 'customIcons';

export default memo(function ContractViewClientInfo({ contractQuery }) {
  const { data } = contractQuery;

  return (
    <div className='client-data base-border  bg-white p-6 pb-3 mt-3 w-full'>
      <div className='text-[18px] leading-[25px] mb-5'>
        {t('order.view.clientDetails')}
      </div>
      {data?.custom?.client_type == CLIENT_TYPE.PHYSICAL.code ? (
        <Grid
          container
          spacing={{ xs: 2, sm: 3, mdm: 3, lg: 3 }}
          rowSpacing={1}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          <Grid item={true} lg={6} md={6} sm={6} xs={6}>
            <div className='flex gap-2'>
              <div className='min-w-[35px] h-[35px] rounded-[10px] bg-[#F0F0FA] flex items-center justify-center mt-[3px]'>
                <UserIcon />
              </div>
              <div className='home-data-item flex flex-col'>
                <div className='data-item-title text-[13px] font-medium text-[#979797]'>
                  {t('contract.view.clientPhysical.custom')}:
                </div>
                <span className='data-item-value text-[14px] leading-[25px] font-medium'>
                  {data?.custom?.surname} {data?.custom?.name}{' '}
                  {data?.custom?.middlename}
                </span>
              </div>
            </div>
          </Grid>
          <Grid item={true} lg={3} md={3} sm={3} xs={3}>
            <div className='flex gap-2'>
              <div className='min-w-[35px] h-[35px] rounded-[10px] bg-[#F0F0FA] flex items-center justify-center mt-[3px]'>
                <CalendarIcon />
              </div>
              <div className='home-data-item flex flex-col'>
                <span className='data-item-title text-[12px] font-medium text-[#979797]'>
                  {t('contract.view.clientPhysical.issue')}:
                </span>
                <span className='data-item-value text-[14px] leading-[25px]'>
                  {data?.custom?.detail?.issue
                    ? moment(data?.custom?.detail?.issue).format('DD/MM/YYYY')
                    : ''}
                </span>
              </div>
            </div>
          </Grid>
          <Grid item={true} lg={3} md={3} sm={3} xs={3}>
            <div className='flex gap-2'>
              <div className='min-w-[35px] h-[35px] rounded-[10px] bg-[#F0F0FA] flex items-center justify-center mt-[3px]'>
                <CakeIcon />
              </div>
              <div className='home-data-item flex flex-col'>
                <span className='data-item-title text-[12px] font-medium text-[#979797]'>
                  {t('contract.view.clientPhysical.birthday')}:
                </span>
                <span className='data-item-value text-[14px] leading-[25px]'>
                  {data?.custom?.detail?.birthday
                    ? moment(data?.custom?.detail?.birthday).format(
                        'DD/MM/YYYY'
                      )
                    : ''}
                </span>
              </div>
            </div>
          </Grid>
          <Grid item={true} lg={6} md={6} sm={6} xs={6}>
            <div className='flex gap-2'>
              <div className='min-w-[35px] h-[35px] rounded-[10px] bg-[#F0F0FA] flex items-center justify-center mt-[3px]'>
                <CommentUserIcon />
              </div>
              <div className='home-data-item flex flex-col'>
                <span className='data-item-title text-[12px] font-medium text-[#979797]'>
                  {t('contract.view.clientPhysical.authority')}:
                </span>
                <span className='data-item-value text-[14px] leading-[25px]'>
                  {data?.custom?.detail?.authority}
                </span>
              </div>
            </div>
          </Grid>
          <Grid item={true} lg={3} md={3} sm={3} xs={3}>
            <div className='flex gap-2'>
              <div className='min-w-[35px] h-[35px] rounded-[10px] bg-[#F0F0FA] flex items-center justify-center mt-[3px]'>
                <BrowserIcon />
              </div>
              <div className='home-data-item flex flex-col'>
                <span className='data-item-title text-[12px] font-medium text-[#979797]'>
                  {t('contract.view.clientPhysical.passportSeries')}:
                </span>
                <span className='data-item-value text-[14px] leading-[25px]'>
                  {data?.custom?.detail?.passport_series}
                </span>
              </div>
            </div>
          </Grid>

          <Grid item={true} lg={3} md={3} sm={3} xs={3}>
            <div className='flex gap-2'>
              <div className='min-w-[35px] h-[35px] rounded-[10px] bg-[#F0F0FA] flex items-center justify-center mt-[3px]'>
                <PhoneIcon />
              </div>
              <div className='home-data-item flex flex-col'>
                <span className='data-item-title text-[13px] font-medium text-[#979797]'>
                  {t('contract.view.clientPhysical.phone')}:
                </span>
                <span className='data-item-value text-[14px] leading-[25px] flex flex-col'>
                  {data?.custom?.phone}
                  {data?.custom?.phone2}
                </span>
              </div>
            </div>
          </Grid>

          <Grid item={true} lg={12} md={12} sm={12} xs={12}>
            <div className='flex gap-2'>
              <div className='min-w-[35px] h-[35px] rounded-[10px] bg-[#F0F0FA] flex items-center justify-center mt-[3px]'>
                <LocationIcon />
              </div>
              <div className='home-data-item flex flex-col'>
                <span className='data-item-title text-[13px] font-medium text-[#979797]'>
                  {t('contract.view.clientPhysical.home')}:
                </span>
                <span className='data-item-value text-[14px] leading-[25px]'>
                  {data?.custom?.detail?.home}
                </span>
              </div>
            </div>
          </Grid>

          <Grid item={true} lg={12} md={12} sm={12} xs={12}>
            <div className='flex gap-2'>
              <div className='min-w-[35px] h-[35px] rounded-[10px] bg-[#F0F0FA] flex items-center justify-center mt-[3px]'>
                <LocationIcon />
              </div>
              <div className='home-data-item flex flex-col'>
                <span className='data-item-title text-[12px] font-medium text-[#979797]'>
                  {t('common.fields.pnfl')}:
                </span>
                <span className='data-item-value text-[14px] leading-[25px]'>
                  {data?.custom?.detail?.pnfl || ''}
                </span>
              </div>
            </div>
          </Grid>

          <Grid item={true} lg={12} md={12} sm={12} xs={12}>
            <div className='contract-staff-details rounded-lg p-1.5 w-full mt-4 mb-1 border border-[#E7E7E7] bg-[#F0F0FA] flex justify-between items-center'>
              <div className='text-[14px]'>
                <span className='font-medium text-[#7F56D9]'>
                  {t('contract.view.staff')}:
                </span>{' '}
                {contractQuery?.data?.staff?.name}
              </div>
              <span className='bg-[#9747FF] rounded-[6px] w-[24px] h-[24px] flex justify-center items-center text-[12px] text-white'>
                i
              </span>
            </div>
            {contractQuery?.data?.comment && (
              <div className='contract-staff-details rounded-lg py-1 px-4 w-full mt-4 mb-1 border border-[#E7E7E7] bg-[#F0F0FA]'>
                <div className='text-[14px]'>
                  <span className='font-medium text-[#7F56D9]'>
                    {t('contract.view.header.comment')}:
                  </span>{' '}
                  {contractQuery?.data?.comment}
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      ) : data?.custom?.client_type == CLIENT_TYPE.LEGAL.code ? (
        <Grid
          container
          spacing={{ xs: 2, sm: 3, mdm: 3, lg: 3 }}
          rowSpacing={1}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          <Grid item={true} lg={12} md={12} sm={12} xs={12}>
            <div className='home-data-item flex'>
              <span className='data-item-title text-[13px] font-medium text-[#979797]'>
                {t('contract.view.clientLegal.name')}:
              </span>
              <span className='data-item-value text-[14px] leading-[25px]'>
                {data?.custom?.name}
              </span>
            </div>
          </Grid>

          <Grid item={true} lg={4} md={4} sm={6} xs={12}>
            <div className='home-data-item flex flex-col'>
              <span className='data-item-title text-[13px] font-medium text-[#979797]'>
                {t('contract.view.clientLegal.phone')}:
              </span>
              <span className='data-item-value text-[14px] leading-[25px]'>
                {data?.custom?.phone}
                {data?.custom?.phone2}
              </span>
            </div>
          </Grid>

          <Grid item={true} lg={8} md={8} sm={6} xs={12}>
            <div className='home-data-item flex flex-col'>
              <span className='data-item-title text-[13px] font-medium text-[#979797]'>
                {t('contract.view.clientLegal.address')}:
              </span>
              <span className='data-item-value text-[14px] leading-[25px]'>
                {data?.custom?.detail?.home}
              </span>
            </div>
          </Grid>
          <Grid item={true} lg={12} md={12} sm={12} xs={12}>
            <div className='home-data-item flex justify-between items-center p-1 bg-gray-100'>
              <span className='data-item-title text-[13px] font-medium text-[#979797]'>
                {t('contract.view.clientLegal.accountNumber')}:
              </span>
              <span className='data-item-value text-[14px] leading-[25px]'>
                {data?.custom?.detail?.account_number}
              </span>
            </div>

            <div className='home-data-item flex justify-between items-center p-1'>
              <span className='data-item-title text-[13px] font-medium text-[#979797]'>
                {t('contract.view.clientLegal.tin')}:
              </span>
              <span className='data-item-value text-[14px] leading-[25px]'>
                {data?.custom?.detail?.inn}
              </span>
            </div>

            <div className='home-data-item flex justify-between items-center p-1 bg-gray-100'>
              <span className='data-item-title text-[13px] font-medium text-[#979797]'>
                {t('contract.view.clientLegal.mfo')}:
              </span>
              <span className='data-item-value text-[14px] leading-[25px]'>
                {data?.custom?.detail?.mfo}
              </span>
            </div>

            <div className='home-data-item flex justify-between items-center p-1'>
              <span className='data-item-title text-[13px] font-medium text-[#979797]'>
                {t('contract.view.clientLegal.oked')}:
              </span>
              <span className='data-item-value text-[14px] leading-[25px]'>
                {data?.custom?.detail?.oked}
              </span>
            </div>
            <div className='contract-staff-details rounded-lg py-1 px-4 w-full mt-4 mb-1 border border-[#E7E7E7] bg-[#F0F0FA]'>
              <div className='text-[14px]'>
                <span className='font-medium text-[#979797]'>
                  {t('contract.view.staff')}:
                </span>{' '}
                {contractQuery?.data?.staff?.name}
              </div>
            </div>
            {contractQuery?.data?.comment && (
              <div className='contract-comment rounded-lg p-4 w-full mt-4 mb-1'>
                <div className='text-[14px]'>
                  <span className='font-medium text-[#979797]'>
                    {t('contract.view.header.comment')}:
                  </span>{' '}
                  {contractQuery?.data?.comment}
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      ) : (
        <div>
          <span className='no-data-found-wrapper'>
            <i className='bi bi-exclamation-octagon text-xl leading-4 mr-1' />{' '}
            {t('common.global.noDataFound')}
          </span>
        </div>
      )}
    </div>
  );
});
