import { Button, Popover } from '@mui/material';
import React, { memo, useState } from 'react';
const colors = [
  '#FFFFFF',
  '#FFA12F',
  '#FF5722',
  '#F42C2C',
  '#4169E1',
  '#5F81FF',
  '#0AB4FF',
  '#1DB954',
  '#2EA52C',
  '#7B68EE',
  '#757380',
  '#202020',
  '#F8306D',
  '#FF00FC',
];

export default memo(function ColorSelect({ formik, fieldName, label }) {
  // const anchorEl =useRef();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openPopover = Boolean(anchorEl);
  const id = `simple-popover-${fieldName}`;

  const handleSelectColor = (color) => formik?.setFieldValue(fieldName, color);
  return (
    <div className='flex flex-col items-center justify-between h-full'>
      <div className='text-gray-400 text-sm text-center'>{label}</div>
      <Button
        onClick={handleClick}
        sx={{
          background: formik?.values?.[fieldName],
          minWidth: 0,
          height: 40,
          width: 40,
          '&:hover': {
            opacity: 0.9,
            background: formik?.values?.[fieldName],
          },
          border:
            formik?.values?.[fieldName] === '#FFFFFF'
              ? '1px solid black'
              : 'none',
        }}
      />
      <Popover
        id={id}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{ sx: { padding: '8px 16px' } }}
      >
        <div className='flex gap-2 flex-wrap w-[132px]'>
          {colors?.map((color) => (
            <Button
              onClick={() => handleSelectColor(color)}
              sx={{
                background: color,
                minWidth: 0,
                height: 20,
                width: 20,
                '&:hover': {
                  opacity: 0.9,
                  background: color,
                },
                border: color === '#FFFFFF' ? '1px solid black' : 'none',
              }}
            />
          ))}
        </div>
      </Popover>
    </div>
  );
});
