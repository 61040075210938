import React, { memo, useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { t } from 'i18next';

import FormActionButtons from 'components/ui/form/FormActionButtons';
import FormCurrencyField from 'components/ui/form/FormCurrencyField';
import FormSelectField from 'components/ui/form/FormSelectField';
import FormSimpleSelectField from 'components/ui/form/FormSimpleSelectField';
import FormCurrencyField2 from 'components/ui/form/FormCurrencyField2';

import FormTextField2 from 'components/ui/form/FormTextField2';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
// import ContactInputForm from './ContactInputForm';
import FormDateField2 from 'components/ui/form/FormDateField2';
// import ChangeOnlyOne from './ChangeOnlyOne';
import useNotification from 'hooks/useNotification';
import DeleteConfirmDialog from 'components/ui/dialogs/DeleteConfirmDialog';

export default memo(function AddConditions({ formik }) {
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);
  const [open, setOpen] = useState(false);
  const [contactId, setContactId] = useState(null);
  const [values, setValues] = useState(JSON.stringify(formik.values));
  const { id } = useParams();
  const sendNotification = useNotification();

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['/crm/crm-settings/fields'],
    queryFn: async function () {
      const response = await axiosPrivate.get('/crm/crm-settings/fields');
      return response.data.data;
    },
    enabled: !hasError,
    onSuccess: (res) => {
      if (!id) {
        const fields = res?.map(({ id, name }) => ({
          field_id: id,
          value: '',
          field_name: name,
        }));
        formik.setFieldValue('fields', fields);
      }
    },
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });
  return (
    <div>
      <Grid
        container
        spacing={{ xs: 0, sm: 0, lg: 0 }}
        columns={{ xs: 12, sm: 12, lg: 12 }}
      >
        <Grid item={true} sm={12} xs={12}>
          <div className='item-wrapper'>
            {/* <div className='item-label'>{t('common.fields.staff')}</div> */}
            <div className='item-value'>
              <FormSelectField
                delay={0}
                duration={0}
                fieldName='user_id'
                label={t('common.fields.staff')}
                formik={formik}
                path={'/dictionary/staffes'}
                // variant='standard'
                size='small'
                sx={{
                  '.MuiSelect-select': { textAlign: 'right' },
                }}
                isitemsizesmall={true}
              />
            </div>
          </div>
        </Grid>

        <Grid item={true} sm={12} xs={12}>
          <div className='item-wrapper'>
            {/* <div className='item-label'>{t('common.fields.budget')}</div> */}
            <div className='item-value'>
              <FormCurrencyField
                delay={0}
                duration={0}
                label={t('common.fields.budget')}
                fieldName='budget'
                formik={formik}
                // variant='standard'
                size='small'
                sx={{
                  '.MuiInputBase-input': { textAlign: 'right' },
                }}
              />
            </div>
          </div>
        </Grid>
        {isLoading || isFetching ? (
          <div className='circular-progress-box my-4'>
            <CircularProgress size={25} />
          </div>
        ) : (
          data?.map((field, i) => (
            <Grid item={true} sm={12} xs={12} key={i}>
              <div className='item-wrapper'>
                {/* <div className='item-label'>{field?.name}</div> */}
                <div className='item-value'>
                  {field?.type === 'SELECT' && (
                    <FormSimpleSelectField
                      delay={0}
                      duration={0}
                      withNestedField
                      fieldName={`fields.${i}.value`}
                      label={field?.name}
                      formik={formik}
                      options={field?.options}
                      // variant='standard'
                      size='small'
                      itemValue='name'
                      sx={{
                        '.MuiSelect-select': { textAlign: 'right' },
                      }}
                      isitemsizesmall={true}
                    />
                  )}
                  {field?.type === 'TEXT' && (
                    <FormTextField2
                      delay={0}
                      duration={0}
                      fieldName={`fields.${i}.value`}
                      formik={formik}
                      options={field?.options}
                      path={'/crm/reject-type'}
                      // variant='standard'
                      size='small'
                      sx={{
                        '.MuiInputBase-input': {
                          textAlign: 'right',
                        },
                      }}
                      isitemsizesmall={true}
                    />
                  )}
                  {field?.type === 'DATE' && (
                    <FormDateField2
                      delay={0}
                      duration={0}
                      fieldName={`fields.${i}.value`}
                      formik={formik}
                      // variant='standard'
                      type='number'
                      size='small'
                      className='[&_.MuiInputBase-input.MuiInput-input]:!text-right'
                      InputProps={{
                        sx: {
                          textAlign: 'right',
                        },
                      }}
                    />
                  )}
                  {field?.type === 'NUMBER' && (
                    <FormCurrencyField2
                      delay={0}
                      duration={0}
                      fieldName={`fields.${i}.value`}
                      formik={formik}
                      // variant='standard'
                      type='number'
                      size='small'
                      sx={{
                        '.MuiInputBase-input': { textAlign: 'right' },
                      }}
                    />
                  )}
                  {/* {field?.type === 'NUMBER1' && (
                    <ChangeOnlyOne
                      delay={0}
                      duration={0}
                      fieldName={`fields.${i}.value`}
                      initialFieldValueName={`fields.${i}.initialValue`}
                      formik={formik}
                      readOnly
                      variant='standard'
                      type='number'
                      size='small'
                      sx={{
                        '.MuiInputBase-input': { textAlign: 'right' },
                      }}
                    />
                  )} */}
                </div>
              </div>
            </Grid>
          ))
        )}
      </Grid>
    </div>
  );
});
