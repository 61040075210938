import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useTopPanel from 'hooks/useTopPanel';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { useState } from 'react';
import { useQuery } from 'react-query';
import DateSelectBox from '../../../residential-complex/items/DateSelectBox';
import moment from 'moment';
import { Tab, Tabs } from '@mui/material';
import StaffFilter from 'components/ui/filters/items/StaffFilter';
import { useLocation } from 'react-router-dom';
import Pipes from './Pipes';
import FiledLeads from './FiledLeads';
import Chart from './chart/Chart';
import Source from './source/Source';
import PriceOneClient from './priceOneClient/PriceOneClient';

function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const General = () => {
  const { t, i18n } = useTranslation();
  const { setComponent } = useTopPanel();
  const axiosPrivate = useAxiosPrivate();
  const { search } = useLocation();
  const [data, setData] = useState([]);
  const [tabValue, setTabValue] = useState('all');
  const [startAndFinishDate, setStartAndFinishDate] = useState({
    startDate: moment()
      .set('years', 2010)
      .startOf('years')
      .format('YYYY-MM-DD'),
    finishDate: moment().format('YYYY-MM-DD'),
  });

  useEffect(() => {
    setComponent(<div className='component-title'>{t('boss.crm.title')}</div>);
  }, [i18n.language]);

  const { isLoading, isFetching, isError } = useQuery({
    queryKey: ['/analytic/crm/tasks', startAndFinishDate, tabValue, search],
    queryFn: async function () {
      const response = await axiosPrivate.get(
        `/analytic/crm/tasks${search || '?'}from=${
          startAndFinishDate.startDate
        }&till=${startAndFinishDate.finishDate}${
          tabValue != 'all' ? '&status=' + tabValue : ''
        }`
      );
      return response.data.data;
    },
    onSuccess: (data) => {
      if (data && data.length > 0) {
        const formattedData = data?.map((data) => ({
          ...data,
          color: getRandomColor(),
        }));
        setData(formattedData);
      }
    },
    // enabled: !hasError,
    // onError: (error) => {
    // 	setHasError(true)
    // },
    retry: false,
  });

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <div>
      <div className='p-2 rounded-md'>
        <div className='flex justify-end mb-2'>
          <div className='w-[150px]'>
            <DateSelectBox
              setStartAndFinishDate={setStartAndFinishDate}
              disabled={isLoading || isFetching}
            />
          </div>
          <div className='w-[150px] ml-1'>
            <StaffFilter
              disabled={isLoading}
              label='common.fields.staff'
              query='user_id'
              size='small'
            />
          </div>
        </div>
        <div className='border border-[#eaeaea] mb-4 py-[24px] px-[32px] rounded-lg'>
          <div className='flex items-center justify-between mb-4'>
            <div className='residential-complex-item-title income-by-residential-complex-title'>
              {t('boss.crm.general.clientFlow')}
            </div>
            <div>
              <Tabs
                value={tabValue}
                onChange={handleChangeTab}
                sx={{ minHeight: 30 }}
              >
                <Tab
                  label={t('common.filter.all')}
                  value={'all'}
                  sx={{ minHeight: 30, padding: '2px 16px' }}
                />
                <Tab
                  label={t('boss.crm.general.active')}
                  value={'active'}
                  sx={{ minHeight: 30, padding: '2px 16px' }}
                />
                <Tab
                  label={t('boss.crm.general.closed')}
                  value={'done'}
                  sx={{ minHeight: 30, padding: '2px 16px' }}
                />
              </Tabs>
            </div>
          </div>
          <div className='income-by-residential-body'>
            <Pipes />
          </div>
        </div>
        <div className='flex mb-4 gap-5'>
          <Chart />
          <Source />
          <PriceOneClient />
        </div>

        <FiledLeads />
      </div>
    </div>
  );
};
export default memo(General);
