import React from 'react';
import moment from 'moment';
export const ONE_DAY_UNIX_VALUE = 86340;
export const DATE_WITH_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const DateItem = ({ data }) => {
  const calculateDate = () => {
    // const allDay =
    //   moment(data.deadlineFinish, DATE_WITH_TIME_FORMAT).unix() -
    //     moment(data.deadlineStart, DATE_WITH_TIME_FORMAT).unix() ===
    //   ONE_DAY_UNIX_VALUE;
    // if (allDay) return '';
    // return data.deadlineStart + '-' + data.deadlineFinish;
    return moment(data?.date, DATE_WITH_TIME_FORMAT).format('HH:mm');
  };

  if (
    moment(data?.date, DATE_WITH_TIME_FORMAT).unix() <
      moment(new Date()).unix() &&
    !data.closedDate
  )
    return (
      <div className='bg-pink-800 h-full border-orange-700'>
        <div className='w-full overflow-hidden text-ellipsis whitespace-nowrap'>
          {data?.crm_lead.name || data?.contractNumber}
        </div>
        <div className='w-full overflow-hidden text-ellipsis whitespace-nowrap'>
          {calculateDate()} {data.title}
          {data.text && ': '}
          {data.text}
        </div>
      </div>
    );

  return (
    <div className='bg-blue-500'>
      <div className='w-full overflow-hidden text-ellipsis whitespace-nowrap'>
        {data?.crm_lead.name || data?.contractNumber}
      </div>
      <div className='w-full overflow-hidden text-ellipsis whitespace-nowrap'>
        {calculateDate()}{' '}
        <span>
          {/* shu yerda backend type nomini berishi kerak */}
          {data.type_id}
          {data.title && ': '}
        </span>
        {data.title}
      </div>
    </div>
  );
};

export default DateItem;
