import { Grid } from '@mui/material';
import { t } from 'i18next';
import React, { memo } from 'react';
import { NumericFormat } from 'react-number-format';
import { useSearchParams } from 'react-router-dom';

const colors = {
  all: {
    color: '#fb5607',
    background: 'rgba(251, 86, 7, 0.08)',
  },
  income: {
    color: '#3a86ff',
    background: 'rgba(58, 134, 255, 0.08)',
  },
  outcome: {
    color: '#8338ec',
    background: 'rgba(131, 56, 236, 0.08)',
  },
  missed: {
    color: '#ff006e',
    background: 'rgba(255, 0, 110, 0.08)',
  },
  notanswer: {
    color: '#ffbe0b',
    background: 'rgba(255, 190, 11, 0.08)',
  },
};

function Filters({ data }) {
  const [searchParam, setSearchParams] = useSearchParams();
  const types = Object.entries(data?.[0]);

  const onClick = (status) => {
    searchParam.set('type', status);
    setSearchParams(searchParam);
  };

  if (!types?.length) return null;
  return (
    <div className='my-10'>
      <Grid
        container
        spacing={2}
        columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
      >
        {types?.map(([key, value], i) => (
          <Grid
            item={true}
            lg={3}
            md={3}
            sm={6}
            xs={12}
            key={`daily-payments-${key}`}
          >
            <div
              className='p-3 cursor-pointer !base-border !shadow-md h-full flex items-center'
              style={{ background: colors?.[key]?.background }}
              onClick={() => onClick(key)}
            >
              <div
                className={'border-l-[5px] flex flex-col pl-2'}
                style={{
                  color: colors?.[key]?.color,
                  borderLeftColor:
                    searchParam.get('type') == key ||
                    (!searchParam.get('type') && key == 'all')
                      ? colors?.[key]?.color
                      : '',
                }}
              >
                <span className='text-base text-gray-600'>
                  {t(`boss.crm.calls.${key}`)}
                </span>

                <NumericFormat
                  value={value}
                  allowNegative={false}
                  displayType={'text'}
                  thousandSeparator={' '}
                  decimalScale={1}
                  className='font-medium text-lg'
                  suffix={''}
                />
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default memo(Filters);
