import React from 'react';
import moment from 'moment';

const DATE_WITH_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
// const ONE_DAY_UNIX_VALUE = 86340;

const DayItem = ({ data }) => {
  // const calculateDate = () => {
  //   const allDay =
  //     moment(data.deadlineFinish, DATE_WITH_TIME_FORMAT).unix() -
  //       moment(data.deadlineStart, DATE_WITH_TIME_FORMAT).unix() ===
  //     ONE_DAY_UNIX_VALUE;
  //   if (allDay) return '';
  //   return data.deadlineStart + '-' + data.deadlineFinish;
  // };

  if (
    moment(data.date, DATE_WITH_TIME_FORMAT).unix() <
      moment(new Date()).unix() &&
    !data.closedDate
  )
    return (
      <div
        className={'w-full line-clamp-2 bg-pink-800 h-full border-orange-700'}
      >
        <p>
          <span>{data?.crm_lead?.name || data?.contractNumber}</span>
          <span>
            {moment(data?.date, DATE_WITH_TIME_FORMAT).format(
              DATE_WITH_TIME_FORMAT
            )}
          </span>
          <span>
            {data?.crm_lead?.name}
            {data?.title && ': '}
          </span>
          {data?.title}
        </p>
      </div>
    );

  return (
    <div>
      <p>
        <span>{data?.crm_lead?.name || data?.contractNumber}</span>
        <span>
          {moment(data?.date, DATE_WITH_TIME_FORMAT).format(
            DATE_WITH_TIME_FORMAT
          )}
        </span>
        <span>
          {data?.crm_lead?.name}
          {data?.title && ': '}
        </span>
        {data?.title}
      </p>
    </div>
  );
};

export default DayItem;
