import { CircularProgress } from '@mui/material';
// import ObjectSelectBox from 'components/boss/ObjectSelectBox';
import DoughnutChart from 'components/ui/charts/DoughnutChart';
import ObjectBlockMultiFilter from 'components/ui/filters/items/ObjectBlockMultiFilter';
import useAuth from 'hooks/useAuth';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useCurrency from 'hooks/useCurrency';
import useTopPanel from 'hooks/useTopPanel';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useQueries } from 'react-query';
import { useLocation } from 'react-router-dom';
import { doughnutTooltip } from 'utils/chartjsTooltips';
import DebtCard from './items/DebtCard';
import RoomQuantityCard from './items/RoomQuantityCard';

const Dashboard = () => {
  const { setComponent } = useTopPanel();
  const { t, i18n } = useTranslation();
  const [{ user }] = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const { currencyData } = useCurrency();
  const [hasError, setHasError] = useState(false);
  const [dailyStatusSum, setDailyStatusSum] = useState(0);
  const { search } = useLocation();

  useEffect(() => {
    setComponent(
      <div className='component-title'>{t('boss.dashboard.title')}</div>
    );
  }, [i18n.language]);

  const [analyticData, dailyPayments] = useQueries([
    {
      queryKey: ['analyticDashboard', search],
      queryFn: async function () {
        const response = await axiosPrivate.get(
          `/analytic/dashboard/index${search || ''}`
        );
        return response.data.data;
      },
      enabled: !hasError,
      onError: (error) => {
        setHasError(true);
      },
      retry: false,
    },
    {
      queryKey: 'dailyPayments',
      queryFn: async function () {
        const response = await axiosPrivate.get('/dictionary/daily');
        return response.data.data;
      },
      onSuccess: (data) => {
        if (data && data.length > 0) {
          setDailyStatusSum(
            data.reduce((acc, curr) => acc + parseFloat(curr.sum), 0)
          );
        }
      },
      enabled: !hasError,
      onError: (error) => {
        setHasError(true);
      },
      retry: false,
    },
  ]);

  return (
    <div className='component-wrapper boss-dashboard-wrapper'>
      <div className='dashboard-actions'>
        <div className='dashboard-owner-greeting'>
          {t('boss.dashboard.welcome')},{' '}
          <span className='text-xl font-medium'>{user?.user?.name}</span>
        </div>
        <div className='max-w-[300px] w-full'>
          <ObjectBlockMultiFilter />
          {/* <ObjectSelectBox
          {/* <ObjectSelectBox
            value={selectedObjectId}
            setValue={handleChangeObject}
            size='small'
          /> */}
        </div>
      </div>

      <div className='dashboard-debts-wrapper'>
        <di className='grid grid-cols-12 gap-4 w-full'>
          <DebtCard
            type='monthly'
            title={t('contract.view.header.monthlyLeft')}
            analyticData={analyticData}
          />
          <DebtCard
            type='total'
            title={t('boss.dashboard.allDebts')}
            analyticData={analyticData}
          />
        </di>
      </div>
      <div className='grid grid-cols-12 col-span-12 gap-4 gap-y-2'>
        <div className='col-span-12 md:col-span-8 lg:col-span-5 dashboard-payments !base-border !shadow-md'>
          <div className='payments-title'>{t('boss.payment.title')}</div>
          <div className='w-[360px] h-[330px] flex items-center mx-auto'>
            {dailyPayments.isLoading || dailyPayments.isFetching ? (
              <div className='flex w-full items-center justify-center'>
                <CircularProgress size={30} color='inherit' />
              </div>
            ) : (
              dailyPayments.data &&
              dailyPayments.data.length > 0 && (
                <DoughnutChart
                  chartLabels={[...dailyPayments.data.map((item) => item.name)]}
                  chartDatasets={[
                    {
                      data: [
                        ...dailyPayments.data.map((item) => ({
                          total:
                            +item.sum +
                            (item?.valute
                              ? +item.valute * currencyData?.sum
                              : 0),
                          sum: item.sum,
                          valute: item?.valute ? item.valute : 0,
                        })),
                      ],
                      borderWidth: 4,
                      hoverBorderWidth: 0,
                      hoverOffset: 15,
                      borderRadius: 4,
                    },
                  ]}
                  optionPlugins={{
                    legend: {
                      display: true,
                      position: 'bottom',
                      align: 'left',
                      reverse: false,
                      labels: {
                        usePointStyle: true,
                        pointStyle: 'rectRounded',
                      },
                    },
                    tooltip: {
                      enabled: false,
                      external: doughnutTooltip('sum', 'valute', 'total'),
                    },
                  }}
                  options={{
                    layout: {
                      padding: {
                        top: 10,
                      },
                    },
                    parsing: {
                      key: 'total',
                    },
                  }}
                />
              )
            )}
          </div>
          <div className='payments-daily-status'>
            {t('boss.dashboard.statusToday')}:{' '}
            {dailyPayments.isLoading || dailyPayments.isFetching ? (
              <div className='flex ml-2'>
                <CircularProgress size={20} color='inherit' />
              </div>
            ) : (
              <NumericFormat
                value={dailyStatusSum}
                displayType={'text'}
                allowNegative={false}
                thousandSeparator={' '}
                decimalScale={3}
                className='payments-daily-status-sum bg-transparent'
                suffix=' UZS'
              />
            )}
          </div>
        </div>
        <div className='dashboard-homes col-span-12 lg:col-span-7'>
          <div className='grid grid-cols-12 gap-4'>
            <div className='col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-6'>
              <RoomQuantityCard
                status='free-homes'
                value={analyticData?.data?.freehomes}
                analyticData={analyticData}
                title={t('common.filter.emptyHomesCount')}
              />
            </div>
            <div className='col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-6'>
              <RoomQuantityCard
                status='sold-homes'
                value={analyticData?.data?.dailycontracts}
                analyticData={analyticData}
                title={t('boss.dashboard.soldToday')}
              />
            </div>
            <div className='col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-12'>
              <RoomQuantityCard
                status='ordered-homes'
                value={analyticData?.data?.orderhomes}
                analyticData={analyticData}
                title={t('boss.dashboard.orderedToday')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(Dashboard);
