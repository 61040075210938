import { Button, Grid } from '@mui/material';
// import EditableTextField from 'components/ui/editable-fields/EditableTextField';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useNotification from 'hooks/useNotification';
import { memo, useCallback, useEffect, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import useMenu from 'hooks/useMenu';
import MiniMap from 'components/MiniMap';
import CRMBlockDroppableCardsSettings from '../card/items/CRMBlockDroppableCardsSettings';

const CRMBlockDroppableColumnSettings = ({
  data = [],
  setOpenAddModal = () => {},
  setOpenOperatorsModal = () => {},
  leadsData = [],
  setLeadsData = () => {},
  setSelected = () => {},
}) => {
  const [enabled, setEnabled] = useState(false);
  const sendNotification = useNotification();
  const axiosPrivate = useAxiosPrivate();
  const { hasChild } = useMenu();

  const view = (code) => hasChild('CRM', code);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  const handleUpdateColumn = (column) => async (newName) => {
    try {
      await axiosPrivate.post(
        `/crm/block-item/${column?.id}`,
        JSON.stringify({
          crm_block_id: column?.crm_block_id,
          name: newName,
          order: column?.order,
          color: column?.color,
          _method: 'put',
        }),
        { headers: { 'Content-Type': 'application/json' } }
      );
    } catch (error) {
      sendNotification({
        msg: error?.response?.data?.message || error?.message,
        variant: 'error',
      });
    }
  };

  const onSelect = useCallback((column) => {
    setSelected(column);
    setOpenAddModal(true);
  }, []);

  if (!enabled) {
    return null;
  }

  return (
    <Droppable
      droppableId='crm-block-columns'
      type='COLUMN'
      direction='horizontal'
    >
      {(provided) => (
        <div
          className='flex crm-block-columns-grid'
          id='crm-blocks-wrapper'
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {data &&
            data.length > 0 &&
            data.map((column, index) => (
              <Draggable
                draggableId={`${column.id}`}
                index={index}
                key={`column-${column.id}`}
              >
                {(provided, snapshot) => (
                  <Grid
                    item={true}
                    sm={1}
                    xs={1}
                    className={`crm-block-grid-column !min-w-[300px] !bg-[#FCFCFC] !p-2 !rounded-md !border !border-[#5959591A] ${
                      snapshot.isDragging ? 'is-dragging' : ''
                    }`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                  >
                    <CRMBlockDroppableCardsSettings
                      leadData={leadsData[`${column.id}`] || []}
                      columnId={column.id}
                      parentDroppableId={`${column.id}`}
                      column={column}
                      handleUpdateColumn={handleUpdateColumn}
                      provided={provided}
                      setLeadsData={setLeadsData}
                      onSelect={onSelect}
                      setSelected={setSelected}
                      editable={true}
                    />
                  </Grid>
                )}
              </Draggable>
            ))}
          {provided.placeholder}
          <div className='sticky right-0 flex flex-col'>
            {view('CRM_ADD_BLOCK_ITEM') && (
              <div className='pl-4 mb-2'>
                <Button
                  type='button'
                  variant='actionLarge'
                  color='primary'
                  onClick={() => setOpenAddModal(true)}
                >
                  <i className='bi bi-plus' />
                </Button>
              </div>
            )}

            {view('CRM_OPERATOR') && (
              <div className='pl-4'>
                <Button
                  type='button'
                  variant='actionLarge'
                  color='primary'
                  onClick={() => setOpenOperatorsModal(true)}
                >
                  <i className='bi bi-person-gear' />
                </Button>
              </div>
            )}
          </div>
          <MiniMap
            mainContainerId='crm-blocks-wrapper'
            itemsNumber={data.length}
          />
        </div>
      )}
    </Droppable>
  );
};
export default memo(CRMBlockDroppableColumnSettings);
