import { FormControl, FormControlLabel, FormGroup, Radio } from '@mui/material';
import FilterAccordion from '../FilterAccordion';
import { useTranslation } from 'react-i18next';
import useSetQuery from 'hooks/useSetQuery';
import useSearch from 'hooks/useSearch';

const CrmCallTypeFilter = ({ disabled = false }) => {
  const { t } = useTranslation();
  const setQuery = useSetQuery();
  const query = useSearch();
  const data = [
    { key: 0, id: undefined, name: t('common.filter.all') },
    { key: 1, id: 'NO_ANSWER', name: t('boss.crm.calls.noAnswer') },
    { key: 2, id: 'CONVERSATION', name: t('common.filter.TalkTime') },
    { key: 3, id: 'USER_BUSY', name: t('common.filter.busy') },
    { key: 4, id: 'UNALLOCATED_NUMBER', name: t('common.filter.notFoundCall') },
  ];

  const handleRepairTypeChange = (id, checked) => {
    if (checked) {
      setQuery('status', id);
    } else {
      setQuery('status');
    }
  };

  return (
    <FilterAccordion
      title={t('boss.crm.calls.callType')}
      code='home-repair-type'
    >
      <FormControl fullWidth color='formColor' type='newFilterRadioGroup'>
        <FormGroup
          aria-labelledby='repair-type-radio-buttons-group'
          name='repair-type-radio-buttons-group-name'
          onChange={(event) =>
            handleRepairTypeChange(event.target.value, event.target.checked)
          }
        >
          {data &&
            data?.length &&
            data?.map((option) => (
              <div className='mb-1 w-full' key={option.id}>
                <FormControlLabel
                  className='w-full'
                  value={option?.id}
                  checked={Boolean(query.get('status') == option?.id)}
                  control={<Radio />}
                  label={t(option.name)}
                  disabled={disabled}
                />
              </div>
            ))}
        </FormGroup>
      </FormControl>
    </FilterAccordion>
  );
};
export default CrmCallTypeFilter;
