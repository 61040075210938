import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import FormActionButtons from 'components/ui/form/FormActionButtons';
import FormTreeSelectField from 'components/ui/form/FormTreeSelectField';
import FormTextField from 'components/ui/form/FormTextField';
import useFormSubmit from 'hooks/useFormSubmit';

const isValidUrl = (url) => {
  try {
    new URL(url);
  } catch (e) {
    return false;
  }
  return true;
};

const validationSchema = yup.object({
  url: yup
    .string()
    .required('settings.crm.validation.baseUrl')
    .test('is-url-valid', 'settings.crm.validation.notUrl', (value) =>
      isValidUrl(value)
    ),
  token: yup.string().required('settings.crm.validation.token'),
  status_id: yup.string().required('settings.crm.validation.statusId'),
  pipe_id: yup.string().required('settings.crm.validation.pipeId'),
  crm_block_item_id: yup
    .string()
    .required('settings.crm.validation.crmBlockItemId'),
});

const AmoCrmSettings = (props) => {
  const { open, setOpen, refetch = () => {}, data } = props;
  const { t } = useTranslation();
  const { submit, isSubmitting } = useFormSubmit();

  const formik = useFormik({
    initialValues: {
      url: '',
      status_id: '',
      token: '',
      pipe_id: '',
      crm_block_id: '',
      crm_block_item_id: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (data?.id) {
        submit(
          { type: 'put', contentType: 'simple' },
          values,
          '/crm/setting/amocrm',
          values.client_id,
          data?.id,
          true,
          handleFinishRequest
        );
      } else
        submit(
          { type: 'post', contentType: 'simple' },
          values,
          '/crm/setting/amocrm',
          values.client_id,
          null,
          true,
          handleFinishRequest
        );
    },
  });

  useEffect(
    (data) => {
      if (data?.id) {
        formik.setValues({
          url: data.url,
          status_id: data.status_id,
          crm_block_id: data.crm_block_id,
          crm_block_item_id: data.crm_block_item_id,
          pipe_id: data.pipe_id,
          token: data.token,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, open]
  );

  const handleFinishRequest = () => {
    refetch();
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      PaperProps={{ className: '!base-border !shadow-none' }}
      disableEscapeKeyDown={true}
    >
      <DialogTitle id='alert-dialog-title'>
        {data?.id && formik.values.client_id ? (
          <span>{t('settings.crm.modal.settingsAmoCrm')}</span>
        ) : (
          <span>{t('settings.crm.modal.addTitle')}</span>
        )}
        <div className='close-btn-wrapper'>
          <IconButton variant='onlyIcon' color='primary' onClick={handleClose}>
            <i className='bi bi-x' />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent className='!max-w-[600px]'>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            spacing={{ xs: 2, sm: 3, lg: 3 }}
            rowSpacing={1}
            columns={{ xs: 12, sm: 12, lg: 12 }}
          >
            <Grid item={true} lg={12} sm={12} xs={12}>
              <FormTextField
                duration={0}
                label={t('common.fields.url')}
                fieldName='url'
                formik={formik}
              />
            </Grid>

            <Grid item={true} lg={12} sm={12} xs={12}>
              <FormTextField
                duration={0}
                label={t('common.fields.token')}
                fieldName='token'
                formik={formik}
              />
            </Grid>
            <Grid item={true} lg={6} sm={6} xs={12}>
              <FormTextField
                duration={0}
                label={t('common.fields.statusId')}
                fieldName='status_id'
                formik={formik}
              />
            </Grid>
            <Grid item={true} lg={6} sm={6} xs={12}>
              <FormTextField
                duration={0}
                label={t('common.fields.pipeId')}
                fieldName='pipe_id'
                formik={formik}
              />
            </Grid>
            <Grid item={true} lg={12} sm={12} xs={12}>
              <div className='border border-gray-400 mt-2 p-[5px] rounded-lg [&>*]:!m-0'>
                <FormTreeSelectField
                  parentFieldName='crm_block_id'
                  childFieldName='crm_block_item_id'
                  label=''
                  childOptionField={'pipes'}
                  formik={formik}
                  parentLabel={'name'}
                  parentValue={'id'}
                  childLabel={'name'}
                  childValue={'id'}
                  variant='standard'
                  path='/crm/block'
                  size='small'
                  onChange={() => {}}
                  progress={false}
                />
              </div>
            </Grid>

            <Grid item={true} sm={12} xs={12}>
              <FormActionButtons
                duration={0}
                isSubmitting={isSubmitting}
                formType='dialog'
                setOpen={setOpen}
                reset={formik.resetForm}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default memo(AmoCrmSettings);
