import { Dialog, DialogContent, DialogTitle, Pagination } from '@mui/material';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const PaymentTermModal = ({ open, setOpen, data = {} }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      PaperProps={{ className: '!shadow-md !base-border' }}
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        id='alert-dialog-title'
        className='bg-gray-100 !text-center mb-2'
      >
        <span>
          {data?.label}({data?.items.length} ta)
        </span>
      </DialogTitle>

      <DialogContent>
        <div className='payment-term-modal-wrapper mt-2'>
          {data?.items && data?.items.length > 0 ? (
            <div className='items-wrapper'>
              {data?.items
                .slice(4 * (page - 1), page * 4)
                .map((item, index) => (
                  <div
                    key={`item-${index}`}
                    className='item-data !shadow-md !base-border'
                  >
                    <div className='item-row'>
                      <div className='item-label'>
                        {t('common.table.custom')}
                      </div>
                      <div className='item-value'>{item?.custom}</div>
                    </div>
                    <div className='item-row'>
                      <div className='item-label'>
                        {t('common.fields.phone')}
                      </div>
                      <div className='item-value'>{item?.phone}</div>
                    </div>
                    <div className='item-row'>
                      <div className='item-label'>
                        {t('boss.contract.title')}
                      </div>
                      <div className='item-value'>{item?.name}</div>
                    </div>
                    <div className='item-row'>
                      <div className='item-label'>
                        {t('boss.payment.averageExpired')}
                      </div>
                      <div className='item-value'>
                        {item?.days} {t('common.fields.day')}
                      </div>
                    </div>
                  </div>
                ))}
              <div className='p-3 mb-2 mt-4 flex items-center justify-center !shadow-md !base-border'>
                <Pagination
                  count={Math.ceil(data?.items.length / 4) || 1}
                  page={page}
                  onChange={(event, newPage) => {
                    setPage(newPage);
                  }}
                  variant='outlined'
                  color='primary'
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          ) : (
            <div>
              <span className='no-data-found-wrapper'>
                <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
                {t('common.global.noDataFound')}
              </span>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default memo(PaymentTermModal);
