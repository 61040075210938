import React, { memo, useEffect, useRef, useState } from 'react';
import Block from './Block';
import { useDraggable } from 'react-use-draggable-scroll';
import { CircularProgress } from '@mui/material';

export default memo(function Shaxmatka({ homes, isLoading }) {
  const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
  const { events } = useDraggable(ref);
  const [i, setI] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (i < homes?.length) setI(i + 1);
    }, 100);
    return () => clearInterval(interval);
  }, [homes?.length, i]);
  return (
    <div
      ref={ref}
      {...events}
      className='h-full w-full overflow-auto my-scroll flex gap-3 showroom-wrapper'
    >
      {isLoading ? (
        <div className='circular-progress-box h-full w-full'>
          <CircularProgress size={50} />
        </div>
      ) : (
        <div className='sheet-base-area flex gap-4'>
          {homes?.slice(0, i)?.map((block, i) => (
            <Block block={block} key={block?.id} blockIndex={i} />
          ))}
        </div>
      )}
    </div>
  );
});
