import React, { memo, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import EventsTable from 'components/ui/tables/EventsTable';
import useTopPanel from 'hooks/useTopPanel';
import SearchInputNavbar from 'components/SearchInputNavbar';
import EventsFilterDrawer from 'components/dashboard/EventsFilterDrawer';
import { useQuery } from 'react-query';
import useAxiosPrivate from 'hooks/useAxiosPrivate';

const Contracts = () => {
  const { setComponent } = useTopPanel();
  const { t, i18n } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setComponent(
      <div className='component-title'>{t('settings.events.title')}</div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

    const { data: blockList } = useQuery({
      queryKey: ['/crm/block'],
      queryFn: async function () {
        const response = await axiosPrivate.get('/crm/block');
        return response.data.data;
      },
  
      enabled: !hasError,
      onError: (error) => {
        setHasError(true);
      },
      retry: false,
    });

  const [refetch, setRefetch] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <div className='component-list-wrapper'>
      <div className='component-list-header mb-2'>
        <div className='header-actions-container  flex flex-row items-center'>
          <div className='header-actions filter-box flex items-center  w-full'>
            <Button
              variant='base'
              color='primary'
              startIcon={<i className='bi bi-filter' />}
              className='!mr-2'
              onClick={() => setOpenFilter(true)}
            >
              {t('common.button.filter')}
            </Button>
            <SearchInputNavbar />
          </div>
          <div className='header-actions action-buttons-box flex items-center justify-center ml-4'>
            <Button
              variant='base'
              color='info'
              className='!mr-2'
              onClick={() => {
                setRefetch(true);
              }}
              disable={`${refetch}`}
            >
              <i
                className={`bi bi-arrow-repeat${
                  refetch ? ' animate-spin' : ''
                }`}
              />
            </Button>
            <Button
              variant='base'
              color='default'
              onClick={() => setOpen(true)}
            >
              <i className='bi bi-gear' />
            </Button>
          </div>
        </div>
      </div>

      <div className='component-table-wrapper h-[calc(100vh-150px)]'>
        <EventsTable
          emitRefetch={{ refetch, setRefetch }}
          dataPath='admin/event/index'
          emitTableColumns={{ open, setOpen }}
          tableName='events'
          headCells={[
            { code: 'dateTime', label: t('common.table.date') },
            { code: 'staff', label: t('common.table.staff') },
            { code: 'type', label: t('common.table.objectName') },
            { code: 'lead', label: t('common.table.name') },
            { code: 'actionType', label: t('common.table.event') },
            { code: 'previous', label: t('common.table.previous') },
            { code: 'next', label: t('common.table.next') },
          ]}
          columns={[
            { code: 'date', type: 'date' },
            { code: 'staff', type: 'nested', childStr: 'name' },
            {
              code: 'type',
              child: i18n.language,
            },
            { code: 'lead', type: 'nested', childStr: 'name' },
            { code: 'actionType' },
            { code: 'previous' },
            { code: 'next' },
          ]}
          noActions
        />
      </div>
      <EventsFilterDrawer blockList={blockList} open={openFilter} setOpen={setOpenFilter} />
    </div>
  );
};

export default memo(Contracts);
