import React, { useEffect, useState } from 'react';
import Doughnut from './Doughnut';
import DateRangeFilter from 'components/ui/filters/items/DateRangeFilter';
import ObjectBlockMultiFilter from 'components/ui/filters/items/ObjectBlockMultiFilter';
import { useQueries } from 'react-query';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useSearch from 'hooks/useSearch';
import useSetQuery from 'hooks/useSetQuery';
import { t } from 'i18next';

export default function Conversion() {
  const axiosPrivate = useAxiosPrivate();
  const query = useSearch();
  const setQuery = useSetQuery();
  const { search } = useLocation();
  const startAndFinishDates = {
    startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    finishDate: moment().format('YYYY-MM-DD'),
  };
  const [hasError, setHasError] = useState(false);
  const [conversions, setConversions] = useState([]);
  const [rejectedLeads, setRejectedLeads] = useState([]);

  useEffect(() => {
    if (!query.get('from') && !query.get('till')) {
      setQuery('from', startAndFinishDates.startDate);
      setQuery('till', startAndFinishDates.finishDate);
    }
  }, []);

  const [conversion, rejected] = useQueries([
    {
      queryKey: ['/analytic/crm/conversions', search],
      queryFn: async function () {
        const response = await axiosPrivate.get(
          `/analytic/crm/conversions${search ? search + '&' : '?'}&start=${
            query.get('from') || startAndFinishDates.startDate
          }&finish=${query.get('till') || startAndFinishDates.finishDate}`
        );
        return response.data.data;
      },
      onSuccess: (data) => {
        if (data) {
          const conversions = [
            {
              name: 'Bekor qilingan',
              count: data?.reject || 0,
            },
            {
              name: 'Jarayonda',
              count: data?.waiting || 0,
            },
          ];
          setConversions(conversions);
        }
      },
      enabled: !hasError && Boolean(query.get('from') && query.get('till')),
      onError: (error) => {
        setHasError(true);
      },
      retry: false,
    },
    {
      queryKey: ['/analytic/crm/rejectleads', startAndFinishDates, search],
      queryFn: async function () {
        const response = await axiosPrivate.get(
          `/analytic/crm/rejectleads${search ? search + '&' : '?'}&start=${
            query.get('from') || startAndFinishDates.startDate
          }&finish=${query.get('till') || startAndFinishDates.finishDate}`
        );
        return response.data.data;
      },
      onSuccess: (data) => {
        if (data?.data && data?.data.length > 0) {
          setRejectedLeads(data?.data);
        }
      },
      enabled: !hasError && Boolean(query.get('from') && query.get('till')),
      onError: (error) => {
        setHasError(true);
      },
      retry: false,
    },
  ]);
  return (
    <div className='component-wrapper boss-sale-wrapper !base-border !shadow-md'>
      <div className='flex justify-between items-center'>
        <div className='font-semibold text-lg'>
          {t('boss.crm.conversion.title')}
        </div>
        <DateRangeFilter
          labels={{
            start: t('common.filter.dateFrom'),
            end: t('common.table.till'),
          }}
        />
      </div>
      <Doughnut
        variant='horizontal'
        title={t('boss.crm.conversion.byLead')}
        label={t('boss.crm.conversion.allLead')}
        dataList={conversions}
        isLoading={conversion?.isLoading || conversion?.isFetching}
      />
      <div className='flex justify-end'>
        <div className='max-w-[300px] w-full'>
          <ObjectBlockMultiFilter />
        </div>
      </div>
      <div className='flex gap-4'>
        <div className='flex-grow'>
          <Doughnut
            variant='vertical'
            title={t('boss.crm.conversion.byLead')}
            label={t('common.table.total')}
            isLoading={rejected?.isLoading || rejected?.isFetching}
            dataList={rejectedLeads}
          />
        </div>
        <div className='flex-grow'>
          <Doughnut
            variant='vertical'
            title={t('boss.crm.conversion.byLead')}
            label={t('common.table.total')}
          />
        </div>
      </div>
    </div>
  );
}
