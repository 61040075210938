import { memo } from 'react';
import { Button, Drawer } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import DateTypeFilter from './items/dateTypeFilter';
import CrmColumnsFilter from './items/crmColumnsFilter';
import StaffFilter from '../ui/filters/items/StaffFilter';
import useSearch from 'hooks/useSearch';
import CalltimeRangeFilter from './items/CalltimeRangeFilter';
import TalktimeRangeFilter from './items/TalktimeRangeFilter';
import HideRepeatedCallFilter from './items/hideRepeatedCallFilter';
import CrmCallTypeFilter from './items/crmCallTypeFilter';

const BossCrmCallsFilterDrawer = ({ open, setOpen, isLoading = false }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const query = useSearch();
  const handleResetFiler = () => {
    navigate(location.pathname, { replace: true });
  };

  return (
    <Drawer
      anchor='left'
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: { padding: '18px', background: 'transparent', boxShadow: 'none' },
      }}
    >
      <div className='dashboard-filter-drawer-wrapper first-letter bg-white h-full rounded-lg border border-[#E5E9EB]'>
        <div className='mb-3 text-end'>
          <Button
            color='error'
            variant='outlined'
            size='small'
            disabled={isLoading}
            onClick={() => handleResetFiler()}
          >
            <i className='bi bi-arrow-repeat text-lg mr-1' />
            {t('common.button.clear')}
          </Button>
        </div>
        <DateTypeFilter disabled={isLoading} />
        <CrmColumnsFilter disabled={isLoading} />
        <CrmCallTypeFilter disabled={isLoading} />
        <StaffFilter
          disabled={isLoading}
          label='common.fields.staff'
          query='user_id'
        />
        <CalltimeRangeFilter
          block={query.get('crm_block')}
          disabled={isLoading}
        />
        <TalktimeRangeFilter
          block={query.get('crm_block')}
          disabled={isLoading}
        />
        <HideRepeatedCallFilter
          label={t('boss.crm.calls.hideRepeat')}
          disabled={isLoading}
        />
      </div>
    </Drawer>
  );
};
export default memo(BossCrmCallsFilterDrawer);
