import { Button } from '@mui/material';
import useTopPanel from 'hooks/useTopPanel';
import React, { memo, useEffect, useState } from 'react';
// import SearchInput from 'components/SearchInput';
// import ContractFiltersComponent from 'components/ui/filters/ContractFiltersComponent';
import ContractTable from 'components/ui/tables/ContractTable';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import useMenu from 'hooks/useMenu';
import useSearch from 'hooks/useSearch';
import ContractFilterDrawer from 'components/dashboard/ContractFilterDrawer';
import ObjectFilter from 'components/ui/filters/items/ObjectFilter';
import SearchInputNavbar from 'components/SearchInputNavbar';

const Contract = () => {
  const { setComponent } = useTopPanel();
  const search = useSearch();
  const { hasChild } = useMenu();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const view = (code) => hasChild('CONTRACTS', code);

  useEffect(() => {
    setComponent(
      <div className='component-title flex gap-4 whitespace-nowrap items-center'>
        {t('contract.title')}
        <ObjectFilter allowClear />
      </div>
    );
  }, [i18n.language]);

  const [refetch, setRefetch] = useState(false);
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);

  return (
    <div className='component-list-wrapper'>
      <div className='component-list-header mb-2'>
        <div className='header-actions-container pt-2 pb-2 flex flex-row items-center'>
          <div className='header-actions filter-box flex items-center'>
            <Button
              variant='base'
              startIcon={<i className='bi bi-filter' />}
              className='!mr-2'
              onClick={() => setExpanded((prev) => !prev)}
            >
              {t('common.button.filter')}
            </Button>
          </div>
          <div className='grow flex items-center gap-2'>
            <SearchInputNavbar inputKey='name' fullWidth />
            <div className='ml-auto flex flex-row'>
              <div className='flex items-center whitespace-nowrap bg-white h-[45px] rounded-[10px] border px-3 gap-1'>
                <div className='w-4 h-4 rounded-sm bg-[#23C840]' />
                {t('contract.completed')}
              </div>
              <div className='flex  ml-2 items-center whitespace-nowrap bg-white h-[45px] rounded-[10px] border px-3 gap-1'>
                <div className='w-4 h-4 rounded-sm bg-[#FF6D6D]' />
                {t('contract.cancelled')}
              </div>
            </div>
          </div>
          <div className='header-actions action-buttons-box flex items-center justify-center ml-4'>
            <Button
              variant='base'
              onClick={() => {
                setRefetch(true);
              }}
              disable={`${refetch}`}
            >
              <i
                className={`bi bi-arrow-repeat ${
                  refetch ? 'animate-spin' : ''
                }`}
              />
            </Button>
            <Link
              to={`${process.env.REACT_APP_BACKEND_URL}/excel/contracts${
                search ? '?' + search : ''
              }`}
              className='no-underline !mx-2'
              // target='_blank'
            >
              <Button variant='base' color='success'>
                <i className='bi bi-download' />
              </Button>
            </Link>
            {view('ADD_CONTRACTS') && (
              <Button
                variant='base'
                color='success'
                className='!mr-2'
                onClick={() => {
                  navigate('/admin/contract/add');
                }}
              >
                <i className='bi bi-plus-circle' />
              </Button>
            )}
            <Button variant='base' onClick={() => setOpen(true)}>
              <i className='bi bi-gear' />
            </Button>
          </div>
        </div>
        {/* {expanded && (
          <div className='my-shadow-2 rounded-lg px-4 w-full mt-2'>
            <ContractFiltersComponent />
          </div>
        )} */}
      </div>

      <div className='component-table-wrapper h-[calc(100vh-160px)]'>
        <ContractTable
          emitRefetch={{ refetch, setRefetch }}
          dataPath='dictionary/contracts'
          emitTableColumns={{ open, setOpen }}
          tableName='contract'
          actionInfo={true}
        />
      </div>
      <ContractFilterDrawer open={expanded} setOpen={setExpanded} />
    </div>
  );
};

export default memo(Contract);
