// import ObjectButtonsBox from "components/boss/ObjectButtonsBox"
import ObjectSelectBox from 'components/boss/ObjectSelectBox';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

const PaymentsByGraph = () => {
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);
  const [selectedObjectId, setSelectedObjectId] = useState(['ALL']);

  const { isLoading, isFetching, isError } = useQuery({
    queryKey: 'paymentsByGraph',
    queryFn: async function () {
      const response = await axiosPrivate.get('/analytic/contracts/intimes');
      return response.data.data;
    },
    onSuccess: (data) => {
      if (data && data.length > 0) {
      }
    },
    enabled: !hasError,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  return (
    <div className='boss-contract-item payments-by-graph !shadow-md !base-border'>
      <div className='boss-contract-item-header'>
        <div className='boss-contract-item-title payments-by-graph-title'>
          {t('boss.contract.paymentGraph')}
        </div>
      </div>

      <div className='boss-contract-item-actions-wrapper'>
        <ObjectSelectBox
          value={selectedObjectId}
          setValue={setSelectedObjectId}
          size='small'
        />
      </div>

      <div className='payments-by-graph-body'>
        <div className='no-data-found-wrapper h-[100px]'>
          <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
          {t('common.global.noDataFound')}
        </div>
      </div>
    </div>
  );
};
export default memo(PaymentsByGraph);
