import React from 'react';
import { Dialog, DialogTitle } from '@mui/material';
import CloseTaskForm from './CloseTaskForm';
import { t } from 'i18next';

export default function TaskCompleteModal({
  data,
  setOpen,
  open,
  refetchFn = () => {},
}) {
  const onClose = () => setOpen(null);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='lg'
      className='!z-[10000]'
      PaperProps={{ className: '!base-border !shadow-none !overflow-hidden' }}
    >
      <DialogTitle
        id='alert-dialog-title'
        className='!bg-[#CDCDCDC7] !text-center !border-b !border-b-[#E6E6E6]'
      >
        <span className='pr-5 font-semibold text-[21px] text-[#374957]'>
          {t('tasks.complete.title')}
        </span>
      </DialogTitle>
      <div className='py-4 px-8 min-w-[600px]'>
        <CloseTaskForm data={data} refetchFn={refetchFn} setOpen={setOpen} />
      </div>
    </Dialog>
  );
}
