import useTopPanel from 'hooks/useTopPanel';
import { memo, useEffect, useState } from 'react';
import CRMBlockColumns from './crm-parts/block-columns/CRMBlockColumns';
import CRMTopPanel from './crm-parts/top-panel/CRMTopPanel';
import useMenu from 'hooks/useMenu';
import { t } from 'i18next';

const CRM = () => {
  const { setComponent } = useTopPanel();
  const { hasChild } = useMenu();
  const [openSettings, setOpenSettings] = useState(false);

  const view = (code) => hasChild('CRM', code);

  useEffect(() => {
    setComponent(t('sidebar.admin.crm'));

    return () => setComponent('');
  }, []);

  return (
    <div className='component-list-wrapper rounded-lg'>
      <CRMTopPanel
        addBlock={view('CRM_ADD_BLOCK')}
        addLead={true}
        openSettings={openSettings}
        setOpenSettings={setOpenSettings}
      />
      <div className='crm-wrapper'>
        <CRMBlockColumns
          openSettings={openSettings}
          setOpenSettings={setOpenSettings}
        />
      </div>
    </div>
  );
};
export default memo(CRM);
