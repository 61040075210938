import { Zoom } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { memo } from 'react';

const ShaxmatkaStageTooltip = (props) => (
  <Tooltip
    {...props}
    placement='right'
    followCursor
    TransitionComponent={Zoom}
    disableInteractive
    title={
      <div className='shaxmatka-block-tooltip-details'>
        <div className='block-name'>{props?.block?.stage}-qavat</div>
        <div className='block-total-homes-number'>
          {props?.block?.count} xonadon
        </div>
        {props?.block?.rooms && props.block.rooms.length > 0 && (
          <div className='block-home-statuses'>
            {props.block.rooms.map((room, index) => (
              <div
                key={`block-${props?.block?.block}-room-${index}`}
                className='home-status-item'
              >
                <div className='home-rooms-number'>{room?.name} xonali:</div>
                <div className='home-count'>{room?.count} ta</div>
              </div>
            ))}
          </div>
        )}
      </div>
    }
    classes={{ tooltip: 'shaxmatka-block-tooltip-wrapper' }}
  />
);

export default memo(ShaxmatkaStageTooltip);
