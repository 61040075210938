import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useTopPanel from 'hooks/useTopPanel';
import {
  ShowroomPanel,
  // ShowroomTopFilter,
  ShowroomTopPanel,
} from './components';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import ShaxmatkaVisualView from '../shaxmatka/shaxmatka-parts/ShaxmatkaVisualView';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

export default memo(function Showroom() {
  const { setComponent } = useTopPanel();
  const { t, i18n } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [searchParams] = useSearchParams();
  const [selectedHomes, setSelectedHomes] = useState([]);
  const [tabValue, setTabValue] = useState(
    localStorage.getItem('showroom-tab') || '1'
  );

  useEffect(() => {
    setComponent(<div className='component-title'>{t('showroom.title')}</div>);
  }, [i18n.language]);

  const setBlocksById = () => {
    let entries = Object.fromEntries(searchParams);
    let objectsQuery = '';
    Object.keys(entries).forEach((item) => {
      if (item.includes('block')) {
        objectsQuery += `${item}=${entries[item]}&`;
      }
    });
    return objectsQuery.length > 0 ? `?${objectsQuery}` : '';
  };

  const { data } = useQuery({
    queryKey: 'showroom-blocks',
    queryFn: async function () {
      const response = await axiosPrivate.get(
        `/admin/home/padezd/${searchParams.get('objectId')}${setBlocksById()}`
      );
      return response.data.data;
    },
    enabled: false,
    retry: false,
  });

  const toggleVisualViewHomeItem = (id, padezdId, blockId) => {
    if (data && data.length > 0) {
      const foundBlockIndex = data?.findIndex((item) => item?.id == blockId);
      if (foundBlockIndex > -1) {
        const padezdIndex = data[foundBlockIndex]?.padezd?.findIndex(
          (item) => item?.id == padezdId
        );
        if (selectedHomes.find((item) => item.id == id)) {
          setSelectedHomes([]);
        } else {
          setSelectedHomes([
            { id: id, padezdIndex, blockIndex: foundBlockIndex },
          ]);
        }
      } else {
        setSelectedHomes([]);
      }
    }
  };

  return (
    <div className='h-full'>
      <div className='base-border px-4 py-1 bg-white'>
        <ShowroomTopPanel tabValue={tabValue} setTabValue={setTabValue} />
      </div>
      <div className='h-[calc(100%-80px)]'>
        {tabValue == 3 && (
          <ShaxmatkaVisualView toggleHomeItem={toggleVisualViewHomeItem} />
        )}
        {tabValue != 3 && <ShowroomPanel tabValue={tabValue} />}
      </div>
    </div>
  );
});
