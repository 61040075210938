/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Tab,
  Tabs,
} from '@mui/material';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueries } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import ShowroomTopFilter from './ShowroomTopFilter';

const ShowroomTopPanel = ({ tabValue, setTabValue }) => {
  const { t } = useTranslation();
  const [hasError, setHasError] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [selectedObjectId, setSelectedObjectId] = useState('');
  const [selectedBlocksId, setSelectedBlocksId] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = useState(null);

  const [objects, blocks] = useQueries([
    {
      queryKey: '/dictionary/objects2',
      queryFn: async function () {
        const response = await axiosPrivate.get('/dictionary/objects2');
        return response.data.data;
      },
      enabled: !hasError,
      onSuccess: (data) => {},
      onError: (error) => {
        setHasError(true);
      },
      retry: false,
    },
    {
      queryKey: '/admin/block/index/{selectedObjectId}',
      queryFn: async function () {
        const response = await axiosPrivate.get(
          `/admin/block/index/${selectedObjectId}`
        );
        return response.data.data;
      },
      onSuccess: (data) => {
        const entries = Object.fromEntries(searchParams);
        const blocksFromPath = [];
        Object.keys(entries).forEach((item) => {
          if (item.includes('block')) {
            blocksFromPath.push(parseInt(entries[item]));
          }
        });
        setSelectedBlocksId(blocksFromPath);
      },
      enabled: false,
      onError: (error) => {
        setHasError(true);
      },
      retry: false,
    },
  ]);

  const handleChangeObject = (event) => {
    const {
      target: { value },
    } = event;
    searchParams.set('objectId', value);
    clearAll();
    setSelectedBlocksId([]);
    setSelectedObjectId(value);
    setSearchParams(searchParams);
  };

  const handleChangeBlocks = (event) => {
    const {
      target: { value },
    } = event;
    searchParams.set(
      `block[${selectedBlocksId.length}]`,
      value[value.length - 1]
    );
    setSearchParams(searchParams);
    setSelectedBlocksId(value);
  };

  const handleDeleteBlockItem = (value) => {
    let leftSelectedBlocks = selectedBlocksId.filter((item) => item != value);
    clearAll();
    leftSelectedBlocks.forEach((item, index) =>
      searchParams.set(`block[${index}]`, item)
    );
    setSearchParams(searchParams);
    setSelectedBlocksId(leftSelectedBlocks);
  };

  const clearAll = () => {
    let entries = Object.fromEntries(searchParams);
    Object.keys(entries).forEach((item) => {
      if (item.includes('block')) searchParams.delete(item);
    });
  };

  const getChipNameById = (id) => {
    let foundBlock =
      blocks.data &&
      blocks.data.length > 0 &&
      blocks.data.find((item) => item.id == id);
    if (foundBlock) {
      return foundBlock.name;
    }
    return '';
  };

  const handleClearSelectBox = () => {
    clearAll();
    setSelectedBlocksId([]);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (objects?.data?.length) {
      const { data } = objects;
      const entries = Object.fromEntries(searchParams);
      if (Object.keys(entries).includes('objectId')) {
        setSelectedObjectId(searchParams.get('objectId'));
      } else {
        setSelectedObjectId(data[0]?.id);
        searchParams.set('objectId', data[0]?.id);
        setSearchParams(searchParams);
      }
    }
  }, [objects?.data, searchParams]);

  useEffect(() => {
    if (selectedObjectId) {
      blocks.refetch();
    }
  }, [selectedObjectId]);

  const openFilterBar = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    localStorage.setItem('showroom-tab', newValue);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className='flex flex-row gap-3 items-center justify-between w-full'>
      <div className='flex flex-row gap-3 items-center w-full'>
        <div>
          <FormControl fullWidth color='formColor'>
            <InputLabel id='objectId-label'>Obyekt</InputLabel>
            <Select
              label='Obyekt'
              labelId='objectId-label'
              id='objectId-select'
              onChange={handleChangeObject}
              value={selectedObjectId}
              color='formColor'
              role='presentation'
              sx={{
                width: 240,
                height: '40px',
                // '.MuiSelect-select .desc': {
                //   display: 'block',
                // },
              }}
              // MenuProps={{ id: 'objectId-select-menu', className: '!block' }}
              size='medium'
            >
              {objects.isLoading || objects.isFetching ? (
                <div className='circular-progress-box'>
                  <CircularProgress size={25} />
                </div>
              ) : objects.data && objects.data.length > 0 ? (
                objects.data.map((item, index) => (
                  <MenuItem
                    value={item.id}
                    key={index + 1}
                    className='small-size'
                  >
                    <div>{item.name}</div>
                    <div className='text-gray-400 hidden desc'>
                      {item?.address}
                    </div>
                  </MenuItem>
                ))
              ) : (
                <div>
                  <span className='no-data-found-wrapper select-box'>
                    <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
                    {t('common.global.noDataFound')}
                  </span>
                </div>
              )}
            </Select>
          </FormControl>
        </div>
        <div>
          <FormControl fullWidth color='formColor'>
            <InputLabel id='blocks-filter-label'>Bloklar</InputLabel>
            <Select
              label='Bloklar'
              labelId='blocks-filter-label'
              id='blocks-filter-multiple-chip'
              multiple
              value={selectedBlocksId}
              onChange={handleChangeBlocks}
              color='formColor'
              sx={{
                height: 40,
                width: 240,
                border: 'none',
                '& MuiOutlinedInput-input': {
                  paddingRight: 20,
                },
              }}
              renderValue={(selected) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    gap: 0.5,
                    overflow: 'hidden',
                  }}
                >
                  {selected?.map((value) => (
                    <Chip
                      key={`block-${value}`}
                      label={getChipNameById(value)}
                      variant='tableBadge'
                      color='primary'
                      onMouseDown={(event) => {
                        event.stopPropagation();
                      }}
                      size='small'
                      onDelete={() => handleDeleteBlockItem(value)}
                    />
                  ))}
                </Box>
              )}
              endAdornment={
                <InputAdornment position='end' className='custom-endAdornment'>
                  <IconButton
                    onClick={() => handleClearSelectBox()}
                    size='small'
                    variant='onlyIcon'
                    sx={{
                      display: selectedBlocksId.length > 0 ? '' : 'none',
                    }}
                    className='select-box-end-adornment-button'
                  >
                    <i className='bi bi-x end-adornment-close-button' />
                  </IconButton>
                </InputAdornment>
              }
            >
              {blocks.isLoading || blocks.isFetching ? (
                <div className='circular-progress-box'>
                  <CircularProgress size={25} />
                </div>
              ) : blocks.data && blocks.data.length > 0 ? (
                blocks.data.map((item, index) => (
                  <MenuItem
                    value={item.id}
                    key={`block-${index + 1}`}
                    className='small-size'
                  >
                    {item.name}
                  </MenuItem>
                ))
              ) : (
                <div>
                  <span className='no-data-found-wrapper select-box'>
                    <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
                    {t('common.global.noDataFound')}
                  </span>
                </div>
              )}
            </Select>
          </FormControl>
        </div>
        <div>
          <Button
            aria-describedby={id}
            variant='contained'
            onClick={openFilterBar}
          >
            Filter
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            sx={{
              marginTop: '5px',
              '& .MuiPaper-root': {
                borderRadius: '8px',
              },
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <ShowroomTopFilter />
          </Popover>
        </div>
      </div>
      <Box
        sx={{
          background: '#76768014',
          padding: '3px 5px',
          borderRadius: '15px',
          minWidth: 'max-content',
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          type='callsHistory'
          className='custom-tab-fulfilled'
        >
          <Tab
            label={t('showroom.type.showroom')}
            sx={{ textTransform: 'none' }}
            value='1'
          />
          <Tab
            label={t('showroom.type.showroomPlus')}
            sx={{ textTransform: 'none', }}
            value='2'
          />
          <Tab
            label={t('showroom.type.visualView')}
            sx={{ textTransform: 'none' }}
            value='3'
          />
        </Tabs>
      </Box>
    </div>
  );
};
export default memo(ShowroomTopPanel);
