import { Button, Dialog, DialogContent } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function AddTriggerModal({ open, setOpen, setOpenModal }) {
  const { t } = useTranslation();
  const handleClose = () => {
    setOpen(false);
  };
  const handleAddTrigger = (type) => {
    setOpen(false);
    setOpenModal(type);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      PaperProps={{ className: '!base-border !shadow-none' }}
      disableEscapeKeyDown={true}
    >
      <DialogContent>
        <div className='min-w-52 grid grid-cols-1 [&>button]:justify-start'>
          <Button
            onClick={() => handleAddTrigger('task')}
            startIcon={<i className='bi bi-list-task' />}
          >
            {t('common.button.createTask')}
          </Button>
          <Button
            onClick={() => handleAddTrigger('lead')}
            startIcon={<i className='bi bi-columns-gap' />}
          >
            {t('common.button.changeLeadStatus')}
          </Button>
          <Button
            onClick={() => handleAddTrigger('staff')}
            startIcon={<i className='bi bi-person-gear' />}
          >
            {t('tasks.actions.changeStaff')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
