import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Popover,
} from '@mui/material';
import FormActionButtons from 'components/ui/form/FormActionButtons';
import FormTextField from 'components/ui/form/FormTextField';
import { useFormik } from 'formik';
import useFormSubmit from 'hooks/useFormSubmit';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const validationSchema = yup.object({
  name: yup.string().required('crm.block.column.addModal.validation.name'),
});
const colors = [
  '#FFA12F',
  '#FFC682',
  '#0AB4FF',
  '#75D5FF',
  '#7B68EE',
  '#A799FF',
  '#1DB954',
  '#62FF99',
  '#F900EA',
  '#FF8BF8',
  '#F42C2C',
  '#FF7E7E',
  '#2C2DA0',
  '#8D8FFF',
  '#00A389',
  '#00DDBA',
  '#FF5722',
  '#FF9C7D',
];
const CRMBlockColumnAddModal = (props) => {
  const {
    open,
    setOpen,
    refetchFn,
    blockId,
    lastOrder = 0,
    selected = {},
    setSelected = () => {},
  } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const { submit, isSubmitting } = useFormSubmit();

  const formik = useFormik({
    initialValues: {
      name: '',
      color: '#87898E',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        crm_block_id: blockId,
        order: selected?.order || lastOrder + 1,
      };
      if (blockId) {
        submit(
          { type: selected?.id ? 'put' : 'post', contentType: 'simple' },
          newValues,
          // '/crm/block-item',
          `/crm/block-item${selected?.id ? '/' + selected?.id : ''}`,
          values.name,
          null,
          true,
          handleFinish
        );
      }
    },
  });

  useEffect(() => {
    if (selected?.id) {
      formik.setValues({
        name: selected?.name || '',
        color: selected?.color || '#87898E',
      });
    }
    return () => {};
  }, [selected]);

  const handleFinish = () => {
    refetchFn();
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    setSelected({});
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleSelectColor = (color) => formik?.setFieldValue('color', color);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      disableEscapeKeyDown={true}
      PaperProps={{ className: '!base-border !shadow-none' }}
    >
      <DialogTitle
        id='alert-dialog-title'
        className='!bg-gray-100 !text-center !mb-4'
      >
        <span>
          {selected?.id
            ? t('crm.block.column.addModal.editTitle')
            : t('crm.block.column.addModal.title')}
        </span>
      </DialogTitle>

      <DialogContent>
        <form onSubmit={formik.handleSubmit} className='pt-2'>
          <Grid
            container
            spacing={{ xs: 2, sm: 2 }}
            rowSpacing={1}
            columns={{ xs: 12, sm: 12 }}
          >
            <Grid item={true} sm={1} xs={1} className='!items-center flex'>
              <Button
                onClick={handleClick}
                sx={{
                  background: formik?.values?.color,
                  minWidth: 0,
                  height: 40,
                  width: 40,
                  '&:hover': {
                    opacity: 0.9,
                    background: formik?.values?.color,
                  },
                }}
              />
              <Popover
                id={id}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                PaperProps={{ sx: { padding: '8px 16px' } }}
              >
                <div className='flex gap-2 flex-wrap w-[160px]'>
                  {colors?.map((color) => (
                    <Button
                      onClick={() => handleSelectColor(color)}
                      sx={{
                        background: color,
                        minWidth: 0,
                        height: 20,
                        width: 20,
                        '&:hover': {
                          opacity: 0.9,
                          background: color,
                        },
                      }}
                    />
                  ))}
                </div>
              </Popover>
            </Grid>
            <Grid item={true} sm={11} xs={11}>
              <FormTextField
                delay={0.1}
                label={t('common.fields.simpleName')}
                fieldName='name'
                formik={formik}
              />
            </Grid>

            <Grid item={true} sm={12} xs={12}>
              <div className='flex justify-end'>
                <FormActionButtons
                  delay={0.2}
                  isSubmitting={isSubmitting}
                  formType='dialog'
                  setOpen={setOpen}
                  // disabled={selected?.id}
                  reset={formik.resetForm}
                />
              </div>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default memo(CRMBlockColumnAddModal);
