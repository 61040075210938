import { ButtonBase, CircularProgress } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

const RoomQuantityCard = ({ value, analyticData, title, status }) => {
  const { t } = useTranslation();

  return (
    <ButtonBase
      className={`${status} homes-item-wrapper !shadow-md !base-border`}
    >
      <div className='homes-item-number'>
        {analyticData.isLoading || analyticData.isFetching ? (
          <div className='flex my-2'>
            <CircularProgress size={32} color='inherit' />
          </div>
        ) : (
          <NumericFormat
            value={value}
            displayType={'text'}
            allowNegative={false}
            thousandSeparator={' '}
            decimalScale={3}
            className='bg-transparent'
          />
        )}
      </div>
      <div className='homes-item-status-name'>{title}</div>
    </ButtonBase>
  );
};

export default RoomQuantityCard;
