import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQueries } from 'react-query';
import { Button, Drawer } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ObjectsFilter from './items/ObjectsFilter';
import DateFromToFilter from './items/dateFromToFilter';
import ContractStatusFilter from './items/GenericRadiosFilter';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import StatusFilter from '../ui/filters/items/StatusFilter';
import { currencyTypeSelectOptions } from 'shared/selectOptionsList';
import SwitchItemFilter from './items/SwitchItemFilter';

const PaymentFilterDrawer = ({
  open,
  setOpen,
  filterData = {},
  isLoading = false,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const handleResetFiler = () => {
    navigate(location.pathname, { replace: true });
  };

  const [hasError, setHasError] = useState(false);
  const [{ data: filterObjects }, { data: paymentTypes }] = useQueries([
    {
      queryKey: '/admin/object/objects',
      queryFn: async function () {
        const response = await axiosPrivate.get('/admin/object/objects');
        return response.data.data;
      },
      enabled: !hasError,
      onError: (error) => {
        setHasError(true);
      },
      retry: false,
    },
    {
      queryKey: '/dictionary/types',
      queryFn: async function () {
        const response = await axiosPrivate.get('/dictionary/types');
        return response.data.data;
      },
      enabled: !hasError,
      onError: (error) => {
        setHasError(true);
      },
      retry: false,
    },
  ]);

  const options = useMemo(
    () =>
      paymentTypes?.map(({ id, name }) => ({
        code: id,
        label: name,
      })),
    [paymentTypes]
  );

  return (
    <Drawer
      anchor='left'
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: { padding: '18px', background: 'transparent', boxShadow: 'none' },
      }}
    >
      <div className='dashboard-filter-drawer-wrapper !pt-2 first-letter bg-white h-full rounded-lg border border-[#E5E9EB]'>
        <div className='mb-3 text-end flex justify-between items-center border-b'>
          <div className='text-[16px] leading-[16px] font-semibold'>
            {t('common.button.filter')}
          </div>
          <Button
            color='error'
            // variant='outlined'
            className='!-mr-[5px]'
            size='small'
            disabled={isLoading}
            onClick={() => handleResetFiler()}
          >
            {t('common.button.clear')}
          </Button>
        </div>
        <DateFromToFilter
          size='small'
          disabled={isLoading}
          labels={{
            start: t('common.filter.dateFrom'),
            end: t('common.table.till'),
          }}
        />
        <StatusFilter />
        <ContractStatusFilter
          options={options}
          queryName='paymentId'
          label={t('common.fields.typeId')}
        />
        <ContractStatusFilter
          options={currencyTypeSelectOptions}
          label={t('common.fields.currency')}
          queryName='isvalute'
        />

        <ObjectsFilter objects={filterObjects?.objects} disabled={isLoading} />
        <SwitchItemFilter
          queryName={'canceled'}
          label={t('contract.cancelled')}
        />
      </div>
    </Drawer>
  );
};
export default PaymentFilterDrawer;
