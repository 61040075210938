import { Button, CircularProgress, Divider, Grid } from '@mui/material';
import FormActionButtons from 'components/ui/form/FormActionButtons';
import FormTextField from 'components/ui/form/FormTextField';
import { useFormik } from 'formik';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useFormSubmit from 'hooks/useFormSubmit';
import React, { Fragment, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import FormDateField from 'components/ui/form/FormDateField';
import FormNumberField from 'components/ui/form/FormNumberField';
import CLIENT_TYPE from 'shared/clientTypeList';
import FormAutocompleteField from 'components/ui/form/FormAutocompleteField';
import FormPhoneField from 'components/ui/form/FormPhoneField';
import FormNumberTextField from 'components/ui/form/FormNumberTextField';
import useTopPanel from 'hooks/useTopPanel';
import {
  citizenshipTypeSelectOptions,
  clientTypeSelectOptions,
} from 'shared/selectOptionsList';
import FormPassportField from 'components/ui/form/FormPassportField';
import { useTranslation } from 'react-i18next';
import SimpleToggleButtonGroup from 'components/ui/simple-fields/toggle-button-group/SimpleToggleButtonGroup';
import FormPatternField from 'components/ui/form/FormPatternField';
import FormSimpleSelectField from 'components/ui/form/FormSimpleSelectField';
import axios from 'axios';
import SimpleRadioGroupField from 'components/ui/simple-fields/radio-group/SimpleRadioGroupField';
import CITIZENSHIP_TYPE from 'shared/citizenshipTypeList';

const physicalValidationSchema = yup.object({
  name: yup.string().required('client.addEdit.validation.physical.name'),
  middlename: yup
    .string()
    .required('client.addEdit.validation.physical.middleName'),
  surname: yup.string().required('client.addEdit.validation.physical.surname'),
  phone: yup
    .string()
    .length(17, 'client.addEdit.validation.physical.phoneValid')
    .required('client.addEdit.validation.physical.phone'),
  phone2: yup
    .string()
    .length(17, 'client.addEdit.validation.physical.phone2Valid')
    .nullable()
    .optional(),
  passport_series: yup
    .string()
    .trim()
    .min(10, 'client.addEdit.validation.physical.passportSeriesFormat')
    .max(10, 'client.addEdit.validation.physical.passportSeriesFormat')
    .required('client.addEdit.validation.physical.passportSeries'),
  issue: yup
    .date()
    .nullable()
    .typeError('client.addEdit.validation.physical.issueValid')
    .required('client.addEdit.validation.physical.issue'),
  authority: yup
    .string()
    .required('client.addEdit.validation.physical.authority'),
  birthday: yup
    .date()
    .nullable()
    .typeError('client.addEdit.validation.physical.birthdayValid')
    .required('client.addEdit.validation.physical.birthday'),
  region_id: yup.string().required('client.addEdit.validation.physical.region'),
  city: yup.string().required('client.addEdit.validation.physical.city'),
  home: yup.string().required('client.addEdit.validation.physical.address'),
  inn: yup
    .string()
    .trim()
    .min(9, 'client.addEdit.validation.physical.tinFormat')
    .max(9, 'client.addEdit.validation.physical.tinFormat')
    .optional(),
  pnfl: yup
    .string()
    .trim()
    .min(14, 'client.addEdit.validation.physical.pnflFormat')
    .max(14, 'client.addEdit.validation.physical.pnflFormat')
    .required('client.addEdit.validation.physical.pnfl'),
  work_place: yup.string().optional(),
});

const legalValidationSchema = yup.object({
  name: yup.string().required('client.addEdit.validation.legal.name'),
  phone: yup
    .string()
    .length(17, 'client.addEdit.validation.legal.phoneValid')
    .required('client.addEdit.validation.legal.phone'),
  phone2: yup
    .string()
    .length(17, 'client.addEdit.validation.legal.phone2Valid')
    .nullable()
    .optional(),
  region_id: yup.string().required('client.addEdit.validation.legal.region'),
  city: yup.string().required('client.addEdit.validation.legal.city'),
  home: yup.string().required('client.addEdit.validation.legal.address'),
  inn: yup
    .string()
    .trim()
    .min(9, 'client.addEdit.validation.legal.tinFormat')
    .max(9, 'client.addEdit.validation.legal.tinFormat')
    .optional(),
  // .required('client.addEdit.validation.legal.tin'),
  mfo: yup.number().required('client.addEdit.validation.legal.mfo'),
  oked: yup.number().required('client.addEdit.validation.legal.oked'),
  account_number: yup
    .string()
    .min(20, {
      label: 'client.addEdit.validation.legal.accountNumberMin',
      value: 20,
    })
    .max(20, {
      label: 'client.addEdit.validation.legal.accountNumberMax',
      value: 20,
    })
    .required('client.addEdit.validation.legal.accountNumber'),
  bank_name: yup.string().required('client.addEdit.validation.legal.bank'),
});

const ClientAddEdit = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const { submit, isSubmitting } = useFormSubmit();
  const [hasError, setHasError] = useState(false);
  const [citizenship, setCitizenship] = useState(
    CITIZENSHIP_TYPE.UZBEKISTAN.code
  );
  const { setComponent } = useTopPanel();
  const [clientType, setClientType] = useState(CLIENT_TYPE.PHYSICAL.code);
  const [isClientSearching, setIsClientSearching] = useState(false);

  const physicalInitialValues = {
    name: '',
    middlename: '',
    surname: '',
    phone: '',
    phone2: '',
    passport_series: '',
    issue: '',
    inn: '',
    pnfl: '',
    authority: '',
    birthday: '',
    region_id: '',
    city: '',
    home: '',
    work_place: '',
  };

  const legalInitialValues = {
    name: '',
    phone: '',
    region_id: '',
    city: '',
    home: '',
    inn: '',
    mfo: '',
    oked: '',
    account_number: '',
    bank_name: '',
  };

  const formikPhysical = useFormik({
    initialValues: physicalInitialValues,
    validationSchema: physicalValidationSchema,
    onSubmit: async (values) => {
      if (id)
        submit(
          { type: 'put', contentType: 'formData' },
          values,
          '/admin/custom/customstore',
          values.name,
          id,
          true
        );
      else
        submit(
          { type: 'post', contentType: 'formData' },
          values,
          '/admin/custom/customstore',
          values.name,
          null,
          true
        );
    },
  });

  const formikLegal = useFormik({
    initialValues: legalInitialValues,
    validationSchema: legalValidationSchema,
    onSubmit: async (values) => {
      if (id)
        submit(
          { type: 'put', contentType: 'formData' },
          values,
          '/admin/custom/legalstore',
          values.name,
          id,
          true
        );
      else
        submit(
          { type: 'post', contentType: 'formData' },
          values,
          '/admin/custom/legalstore',
          values.name,
          null,
          true
        );
    },
  });

  const { isLoading, isFetching } = useQuery({
    queryKey: 'customerSingle',
    queryFn: async function () {
      const response = await axiosPrivate.get(`/admin/custom/edit/${id}`);
      return response.data.data;
    },
    enabled: !hasError && !!id,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  useEffect(() => {
    setComponent(
      <div className='component-title'>
        {id ? (
          <span>{t('client.addEdit.editTitle')}</span>
        ) : (
          <span>{t('client.addEdit.addTitle')}</span>
        )}
      </div>
    );
  }, [i18n.language]);

  const handleClientType = (value) => {
    formikPhysical.resetForm();
    formikLegal.resetForm();
    setClientType(value);
  };

  const handleSearchClient = () => {
    if (
      formikPhysical.values.passport_series &&
      formikPhysical.values.birthday &&
      !(
        formikPhysical.touched.passport_series &&
        Boolean(formikPhysical.errors.passport_series) &&
        formikPhysical.touched.birthday &&
        Boolean(formikPhysical.errors.birthday)
      )
    ) {
      setIsClientSearching(true);
      axios
        .get(
          `https://backsmartsales.uz/api/takedata?passport_series=${formikPhysical.values.passport_series}&birthday=${formikPhysical.values.birthday}`
        )
        .then((response) => {
          if (response && response.data && response.data.length > 0) {
            const dataJSON = response.data[0];
            if (
              dataJSON &&
              dataJSON?.data?.info &&
              dataJSON?.data?.info &&
              dataJSON?.data?.info?.data
            ) {
              const region_id =
                data?.find(
                  (reg) => reg?.code == dataJSON?.data?.passport?.region_id
                )?.id ||
                formikPhysical.values.region_id ||
                '';
              const address = dataJSON?.data?.passport?.address?.split(' ');
              const city =
                address?.length > 1
                  ? address?.[0] + ' ' + address?.[1]
                  : formikPhysical.values.region_id || '';
              const newValues = {
                name: dataJSON?.data?.info?.data?.name || '',
                middlename: dataJSON?.data?.info?.data?.patronymic_name || '',
                surname: dataJSON?.data?.info?.data?.sur_name || '',
                phone: formikPhysical.values.phone || '',
                phone2: formikPhysical.values.phone2 || '',
                passport_series: formikPhysical.values.passport_series || '',
                issue: dataJSON?.data?.info?.data?.given_date || null,
                authority: dataJSON?.data?.info?.data?.given_place || '',
                birthday: formikPhysical.values.birthday || '',
                region_id,
                city,
                home: dataJSON?.data?.info?.data?.living_place
                  ? dataJSON?.data?.info?.data?.living_place
                  : dataJSON?.data?.info?.data?.address,
                work_place: formikPhysical.values.work_place || '',
                inn: dataJSON?.data?.info?.data?.tin || '',
                pnfl: dataJSON?.data?.info?.data?.pinfl || '',
              };
              formikPhysical.setValues(newValues);
            }
          }
        })
        .catch((err) => {})
        .finally(() => setIsClientSearching(false));
    } else {
    }
  };

  const { data } = useQuery({
    queryKey: '/dictionary/regions',
    queryFn: async () => {
      const response = await axiosPrivate.get('/dictionary/regions');
      return response.data.data;
    },
    retry: false,
  });

  return (
    <div className='component-add-edit-wrapper mx-4 pb-6'>
      <div className='component-add-edit-body mt-3'>
        {isLoading || isFetching ? (
          <div className='circular-progress-box py-5'>
            <CircularProgress size={35} />
          </div>
        ) : (
          <form
            onSubmit={
              clientType == CLIENT_TYPE.PHYSICAL.code
                ? formikPhysical.handleSubmit
                : formikLegal.handleSubmit
            }
          >
            <Grid
              container
              spacing={{ xs: 2, sm: 3, lg: 3 }}
              rowSpacing={1}
              columns={{ xs: 12, sm: 12, lg: 12 }}
            >
              <Grid item={true} sm={12} xs={12}>
                <SimpleToggleButtonGroup
                  delay={0.1}
                  value={clientType}
                  changeFn={handleClientType}
                  options={clientTypeSelectOptions}
                />
              </Grid>

              {clientType == CLIENT_TYPE.PHYSICAL.code ? (
                <Fragment>
                  <Grid item={true} sm={12} xs={12}>
                    <Grid
                      container
                      spacing={{ xs: 2, sm: 3, lg: 3 }}
                      rowSpacing={1}
                      columns={{ xs: 12, sm: 12, lg: 12 }}
                    >
                      <Grid item={true} sm={12} xs={12}>
                        <SimpleRadioGroupField
                          delay={0}
                          name='citizenship'
                          label={t('common.fields.citizenship')}
                          value={citizenship}
                          changeFn={(value) => setCitizenship(value)}
                          options={citizenshipTypeSelectOptions}
                          className='flex'
                        />
                      </Grid>
                      <Grid item={true} lg={4} sm={6} xs={12}>
                        {citizenship == CITIZENSHIP_TYPE.UZBEKISTAN.code ? (
                          <FormPassportField
                            delay={0.1}
                            label={t('common.fields.passportSeries')}
                            fieldName='passport_series'
                            formik={formikPhysical}
                          />
                        ) : (
                          <FormTextField
                            delay={0.1}
                            label={t('common.fields.passportSeries')}
                            fieldName='passport_series'
                            formik={formikPhysical}
                          />
                        )}
                      </Grid>
                      <Grid item={true} lg={4} sm={6} xs={12}>
                        <FormDateField
                          delay={0.2}
                          label={t('common.fields.birthday')}
                          fieldName='birthday'
                          formik={formikPhysical}
                        />
                      </Grid>
                      <Grid item={true} lg={4} sm={6} xs={12}>
                        {citizenship == CITIZENSHIP_TYPE.UZBEKISTAN.code && (
                          <Button
                            variant='outlined'
                            color='info'
                            onClick={() => handleSearchClient()}
                            disabled={isClientSearching}
                            className='!mt-2 !h-10'
                          >
                            {isClientSearching ? (
                              <div className='flex items-center justify-center'>
                                <CircularProgress size={17.5} color='inherit' />
                              </div>
                            ) : (
                              <div className='flex items-center text-base'>
                                <i className='bi bi-search mr-1 text-lg' />
                                {t('client.addEdit.search')}
                              </div>
                            )}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item={true} sm={12} xs={12}>
                    <div className='mt-2'>
                      <Divider />
                      <div className='text-lg font-medium mt-3 -mb-2'>
                        {t('client.addEdit.clientDetails')}
                      </div>
                    </div>
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormTextField
                      delay={0.3}
                      label={t('common.fields.name')}
                      fieldName='name'
                      formik={formikPhysical}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormTextField
                      delay={0.4}
                      label={t('common.fields.surname')}
                      fieldName='surname'
                      formik={formikPhysical}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormTextField
                      delay={0.5}
                      label={t('common.fields.middleName')}
                      fieldName='middlename'
                      formik={formikPhysical}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormPhoneField
                      delay={0.6}
                      label={t('common.fields.phone')}
                      fieldName='phone'
                      formik={formikPhysical}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormPhoneField
                      delay={0.7}
                      label={t('common.fields.phone2')}
                      fieldName='phone2'
                      formik={formikPhysical}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormPatternField
                      delay={0.8}
                      label={t('common.fields.tin')}
                      fieldName='inn'
                      formik={formikPhysical}
                      format='#########'
                    />
                  </Grid>

                  <Grid item={true} sm={12} xs={12}>
                    <div className='mt-2'>
                      <Divider />
                      <div className='text-lg font-medium mt-3 -mb-2'>
                        {t('client.addEdit.passportDetails')}
                      </div>
                    </div>
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormDateField
                      delay={0.9}
                      label={t('common.fields.issue')}
                      fieldName='issue'
                      formik={formikPhysical}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormTextField
                      delay={1}
                      label={t('common.fields.authority')}
                      fieldName='authority'
                      formik={formikPhysical}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormPatternField
                      delay={1.1}
                      label={t('common.fields.pnfl')}
                      fieldName='pnfl'
                      formik={formikPhysical}
                      format='##############'
                    />
                  </Grid>

                  <Grid item={true} sm={12} xs={12}>
                    <div className='mt-2'>
                      <Divider />
                      <div className='text-lg font-medium mt-3 -mb-2'>
                        {t('client.addEdit.addressDetails')}
                      </div>
                    </div>
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormSimpleSelectField
                      delay={1.2}
                      options={data}
                      fieldName='region_id'
                      label={t('common.fields.region')}
                      formik={formikPhysical}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormTextField
                      delay={1.3}
                      label={t('common.fields.city')}
                      fieldName='city'
                      formik={formikPhysical}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormTextField
                      delay={1.4}
                      label={t('common.fields.home')}
                      fieldName='home'
                      formik={formikPhysical}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormTextField
                      delay={1.5}
                      label={t('common.fields.workPlace')}
                      fieldName='work_place'
                      formik={formikPhysical}
                    />
                  </Grid>
                </Fragment>
              ) : (
                <Fragment>
                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormTextField
                      delay={0.1}
                      label={t('common.fields.companyName')}
                      fieldName='name'
                      formik={formikLegal}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormPhoneField
                      delay={0.2}
                      label={t('common.fields.phone')}
                      fieldName='phone'
                      formik={formikLegal}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormPhoneField
                      delay={0.3}
                      label={t('common.fields.phone2')}
                      fieldName='phone2'
                      formik={formikLegal}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormTextField
                      delay={0.4}
                      label={t('common.fields.bank')}
                      fieldName='bank_name'
                      formik={formikLegal}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormNumberField
                      delay={0.5}
                      label={t('common.fields.tin')}
                      fieldName='inn'
                      formik={formikLegal}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormNumberField
                      delay={0.6}
                      label={t('common.fields.mfo')}
                      fieldName='mfo'
                      formik={formikLegal}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormNumberField
                      delay={0.7}
                      label={t('common.fields.oked')}
                      fieldName='oked'
                      formik={formikLegal}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormNumberTextField
                      delay={0.8}
                      label={t('common.fields.accountNumber')}
                      fieldName='account_number'
                      formik={formikLegal}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormAutocompleteField
                      delay={0.9}
                      fieldName='region_id'
                      label={t('common.fields.region')}
                      formik={formikLegal}
                      path={'/dictionary/regions'}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormTextField
                      delay={1}
                      label={t('common.fields.city')}
                      fieldName='city'
                      formik={formikLegal}
                    />
                  </Grid>

                  <Grid item={true} lg={4} sm={6} xs={12}>
                    <FormTextField
                      delay={1.1}
                      label={t('common.fields.companyAddress')}
                      fieldName='home'
                      formik={formikLegal}
                    />
                  </Grid>
                </Fragment>
              )}

              <Grid item={true} sm={12} xs={12}>
                <FormActionButtons delay={1.6} isSubmitting={isSubmitting} />
              </Grid>
            </Grid>
          </form>
        )}
      </div>
    </div>
  );
};

export default ClientAddEdit;
