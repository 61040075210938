import React, { useEffect, useRef } from 'react';
import moment from 'moment';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import DateItem from './DateItem';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const ONE_DAY_UNIX_VALUE = 86340;
export const DATE_WITH_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_FORMAT = 'YYYY-MM-DD';

const WeekView = ({
  data,
  date,
  dateRange,
  setDateRange,
  setTabIndex,
  setSelected,
  onMoreLinkClick,
}) => {
  const calendarRef = useRef(null);
  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      const events = [];
      data.forEach((item) => {
        const allDay =
          moment(item.deadlineFinish, DATE_WITH_TIME_FORMAT).unix() -
            moment(item.deadlineStart, DATE_WITH_TIME_FORMAT).unix() ===
          ONE_DAY_UNIX_VALUE;
        events.push({
          id: String(item.id),
          allDay,
          resourceId: item,
          start: new Date(moment(item.date, DATE_WITH_TIME_FORMAT).format()),
          end: new Date(moment(item.date, DATE_WITH_TIME_FORMAT).format()),
        });
      });
      const calendarApi = calendarRef?.current?.getApi();

      calendarApi?.gotoDate(
        new Date(moment(date, DATE_FORMAT).format()).toISOString()
      );

      calendarApi?.removeAllEventSources();
      calendarApi?.addEventSource([...events]);
    }
  }, [data, calendarRef, date]);

  // handle open complete modal
  const handleOpenCompleteModal = (task) => {
    if (!task.closedDate) return setSelected(task);
    if (task?.crm_lead?.id)
      navigate(`/admin/crm/lead/edit/${task?.crm_lead?.id}`);
  };

  // // handle next
  const handleGoToDay = (date) => {
    setTabIndex('day');
    setDateRange({
      from: moment(date).startOf('day').format(DATE_WITH_TIME_FORMAT),
      till: moment(date).endOf('day').format(DATE_WITH_TIME_FORMAT),
    });
  };

  // handle add date
  const handleAddDate = (e) => {
    e.stopPropagation();
    const newDate = moment(dateRange.from, DATE_WITH_TIME_FORMAT).add(
      1,
      'week'
    );
    setDateRange({
      from: newDate.startOf('week').format(DATE_WITH_TIME_FORMAT),
      till: newDate.endOf('week').format(DATE_WITH_TIME_FORMAT),
    });
  };

  // handle delete date
  const handleDeleteDate = (e) => {
    e.stopPropagation();
    const newDate = moment(dateRange.from, DATE_WITH_TIME_FORMAT).subtract(
      1,
      'week'
    );
    setDateRange({
      from: newDate.startOf('week').format(DATE_WITH_TIME_FORMAT),
      till: newDate.endOf('week').format(DATE_WITH_TIME_FORMAT),
    });
  };

  return (
    <FullCalendar
      ref={calendarRef}
      height={'calc(100vh - 200px)'}
      locale={'Ru-ru'}
      initialView={'timeGridWeek'}
      // dateClick={goToOneDate}
      eventContent={(e) => {
        return <DateItem data={e?.event?._def?.extendedProps?.resourceId} />;
      }}
      eventClick={(e) => {
        handleOpenCompleteModal(e?.event?._def?.extendedProps?.resourceId);
      }}
      moreLinkClick={onMoreLinkClick}
      moreLinkContent={(e) => {
        return (
          <div>
            {e.shortText} {t('tasks.title')}
          </div>
        );
      }}
      dayHeaderContent={(e) => {
        return (
          <div
            onClick={() => handleGoToDay(e.date)}
            className='flex items-center h-[49px] cursor-pointer'
          >
            {e.dow === 1 && (
              <Button
                onClick={handleDeleteDate}
                sx={{
                  minWidth: 30,
                  marginRight: 'auto',
                  justifySelf: 'start',
                  justifyContent: 'start',
                }}
              >
                <i className='bi bi-chevron-left' />
              </Button>
            )}
            <span>
              {moment(e.date).format('ddd')}, {moment(e.date).format('D')}
            </span>
            {e.dow === 0 && (
              <Button onClick={handleAddDate} sx={{ minWidth: 30 }}>
                <i className='bi bi-chevron-right' />
              </Button>
            )}
          </div>
        );
      }}
      firstDay={1}
      allDayText={'Kun davomida'}
      // eventClassNames={'styles.container_event'}
      allDaySlot={true}
      dayMaxEvents={3}
      dayCellClassNames={'m_fc_AllDay'}
      viewClassNames={'c_container'}
      selectMirror={true}
      dragScroll={true}
      eventDisplay='block'
      eventMaxStack={2}
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      progressiveEventRendering
      headerToolbar={false}
      dayHeaders={true}
      dayHeaderClassNames={'!no-underline'}
      nowIndicator={false}
      slotLabelFormat={[
        {
          hour: '2-digit',
          minute: '2-digit',
        },
      ]}
    />
  );
};

export default WeekView;
