import { Button } from '@mui/material';
import SearchInput from 'components/SearchInputNavbar';
import useTopPanel from 'hooks/useTopPanel';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BaseTable from 'components/ui/tables/BaseTable';
import SentMessageFilterDrawer from 'components/dashboard/SentMessageFilterDrawer';
import { useNavigate } from 'react-router-dom';

const SentMessages = () => {
  const { t, i18n } = useTranslation();
  const { setComponent } = useTopPanel();
  const [refetch, setRefetch] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);

  useEffect(() => {
    setComponent(
      <div className='component-title'>{t('settings.sentMessages.title')}</div>
    );
  }, [i18n.language]);

  const onClickRow = useCallback(
    (row) => {
      if (row?.type == 1) navigate(`/admin/contract/view/${row?.object_id}`);
    },
    [navigate]
  );

  return (
    <div className='component-list-wrapper'>
      <div className='component-list-header mb-2'>
        <div className='header-actions-container py-3 flex flex-row items-center'>
          <div className='header-actions filter-box flex items-center px-4 w-full'>
            <Button
              variant='base'
              color='primary'
              startIcon={<i className='bi bi-filter' />}
              className='!mr-2'
              onClick={() => setOpenFilter(true)}
            >
              {t('common.button.filter')}
            </Button>
            <SearchInput inputKey='name' />
          </div>
          <div className='header-actions action-buttons-box py-3 px-4 flex gap-2 items-center justify-center'>
            <Button
              variant='base'
              color='info'
              onClick={() => {
                setRefetch(true);
              }}
              disable={`${refetch}`}
            >
              <i
                className={`bi bi-arrow-repeat${
                  refetch ? ' animate-spin' : ''
                }`}
              />
            </Button>

            <Button
              variant='base'
              color='default'
              onClick={() => setOpen(true)}
            >
              <i className='bi bi-gear' />
            </Button>
          </div>
        </div>
      </div>

      <div className='component-table-wrapper h-[calc(100vh-180px)]'>
        <BaseTable
          emitRefetch={{ refetch, setRefetch }}
          dataPath='admin/sms/index'
          emitTableColumns={{ open, setOpen }}
          tableName='sent-messages'
          headCells={[
            { code: 'phone', label: t('common.table.receiver') },
            { code: 'staff', label: t('common.table.sender') },
            { code: 'status', label: t('common.table.status') },
            { code: 'text', label: t('common.table.sms') },
          ]}
          columns={[
            { code: 'phone' },
            { code: 'staff', type: 'nested', childStr: 'name' },
            { code: 'status' },
            { code: 'text' },
          ]}
          noActions
          onClickRow={onClickRow}
        />
      </div>
      <SentMessageFilterDrawer open={openFilter} setOpen={setOpenFilter} />
    </div>
  );
};

export default memo(SentMessages);
